import React, { useState } from 'react';
import { 
  Box, Typography, Grid, Card, Button, Container, Divider,
  IconButton,
  Modal,
  TextField,
  useTheme
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/system';
import { motion } from 'framer-motion'; // Animation library
import dsa from '../assets/dsa.png';
import dsa2 from '../assets/dsa2.png';
import dsa3 from '../assets/dsa3.png'; // New course image
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate,Link } from 'react-router-dom';
import { DSA_COMPLETE_LEVEL_1_PRICE, DSA_COMPLETE_LEVEL_2_PRICE, DSA_REVISE_LEVEL_1_PRICE, PAYMENT_LIVE_KEY, PLATFORM_ACCESS_PRICE } from '../common/constants';
import proxy from '../api/axios';
import { Helmet } from 'react-helmet';

// Styled components for cleaner and reusable UI elements
const StyledCard = styled(Card)(({ theme }) => ({
  padding: '30px',
  borderRadius: '15px',
  boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
  textAlign: 'center',
  backgroundColor: theme.palette.mode === 'dark' ? '#222' : '#fff',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.03)',
    boxShadow: '0 12px 36px rgba(0,0,0,0.15)',
  },
}));

const FeatureItem = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '1rem',
  marginBottom: '12px',
  color: theme.palette.text.secondary,
}));

const HeaderButton = styled(Button)({
  borderRadius: '30px',
  padding: '12px 24px',
  fontSize: '1rem',
  textTransform: 'none',
});

const DSACoursePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [failedPaymentModalOpen, setFailedPaymentModalOpen] = useState(false);
  const [amount, setAmount] = useState(1); // Assume amount is $100 for now
  const handleRetryPayment = () =>{
    setFailedPaymentModalOpen(false);
  }
  const handlePayment = async () => {
    setLoading(true);
    try {
      const courseBooking = {
        name,
        email,
        contactNumber,
        type: selectedSection
      }
      // Create an order on the backend
      const orderResponse = await proxy.post('/payments/create-dsa-order',
        { amount, currency: 'INR', courseBooking });
      const { orderId } = orderResponse.data;
  
      // Razorpay options
      const options = {
        key: PAYMENT_LIVE_KEY,
        amount: amount * 100,
        currency: 'INR',
        name: 'CrafterHack',
        description: 'Enroll into DSA Guidance course',
        order_id: orderId,
        handler: async (response) => {
          // Handle payment success
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
          
          const verifyResponse = await proxy.post('/payments/verify-course-payment', {
            razorpay_order_id,
            razorpay_payment_id,
            razorpay_signature,
            amount
          });
  
          if (verifyResponse.data.status === 'success') {
            // Close the booking modal and open the confirmation modal
            setModalOpen(false);
            setConfirmationModalOpen(true);
          } else {
            setFailedPaymentModalOpen(true);
          }
        },
        prefill: {
          name: name,
          email: email,
          contact: contactNumber,
        },
        theme: {
          color: '#3399cc',
        },
      };
  
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Payment Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEnrollment = (amount, title) => {
    setModalOpen(true);
    setSelectedSection(title);
    setAmount(amount);
  }
  const topics = [
    'Basics', 
    'Patterns', 
    'Number System', 
    'Arrays', 
    'Searching & Sorting', 
    '2-D Array',
    'ArrayList', 
    'Strings and StringBuilder',
    'Recursion & Backtracking', 
    'Time & Space Complexity', 
    'Stack & Queues', 
    'Linked List', 
    'Generic Trees', 
    'Binary Trees', 
    'Binary Search Trees', 
    'HashMap & Heap', 
    'Graph', 
    'Dynamic Programming', 
    'Trie'
  ];
  

  return (
    <Container maxWidth="lg" sx={{ py: 8, borderRadius: '10px', background: theme.palette.mode === 'dark' ? '#333' : '#fff',}}>
      <Helmet>
        <title>Programs Offered to Master DSA | CrafterHack</title>
        <meta
          name="description"
          content={`This is the page for viewing all the programs offered by crafterhack to master the dsa skills`}
        />
      </Helmet>
      {/* Header Section */}
      <Box sx={{ textAlign: 'center', mb: 8 }}>
        <Typography variant="h4" fontWeight="700" gutterBottom>
          Master DSA with Comprehensive Guidance
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
          Choose the plan that suits your learning needs and start your journey towards excellence.
        </Typography>
        <HeaderButton href='#courses' variant="contained" color="primary" size="large" sx={{ mr: 2, background:'rgb(11, 102, 115)' }}>
          Get Started Now
        </HeaderButton>
        <HeaderButton href="#benefits" variant="outlined" color="primary" size="large">
          Learn More
        </HeaderButton>
      </Box>

      {/* Subscription Plans */}
      <Grid id='courses' container spacing={5} sx={{ mb: 2 }}>
        {/* Plan 1 */}

        <Grid item xs={12} md={4}>
          <StyledCard sx={{ position: 'relative', overflow: 'hidden' }}>
            <Box
              sx={{
                backgroundColor: 'rgb(11 102 115)',
                color: 'white',
                py: 1,
                px: 2,
                textAlign: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                fontWeight: 'bold',
                fontSize: '0.875rem',
                letterSpacing: 1
              }}
            >
              Starting on 15th Jan 2025
            </Box>
            <Typography variant="h6" fontWeight="bold" sx={{ mt: 3, mb: 3 }}>
              Complete DSA Guidance Course (Level 2)
            </Typography>
            <motion.img
              src={dsa2}
              alt="Complete Course"
              style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />
            <Typography variant="body1" sx={{ mb: 2 }}>
              Unlock all features, including contests, mock interviews, and doubt sessions.
              Duration: 4 months
            </Typography>
            <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
              ₹{DSA_COMPLETE_LEVEL_2_PRICE}
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => handleEnrollment(DSA_COMPLETE_LEVEL_2_PRICE, 'Complete DSA Course Level 2')}
              sx={{ borderRadius: '30px', padding: '12px 24px' }}
            >
              Enroll Now
            </Button>
          </StyledCard>
        </Grid>


        <Grid item xs={12} md={4}>
          <StyledCard sx={{ position: 'relative', overflow: 'hidden' }}>
            {/* Banner with RGB background */}
            <Box
              sx={{
                backgroundColor: 'rgb(11, 102, 115)', // Teal banner
                color: 'white',
                py: 1,
                px: 2,
                textAlign: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                fontWeight: 'bold',
                fontSize: '0.875rem',
                letterSpacing: 1
              }}
            >
              Starts immediately
            </Box>

            <Typography variant="h6" fontWeight="bold" sx={{ mt: 3, mb: 3 }}>
              Platform Access & Questions Only
            </Typography>

            <motion.img
              src={dsa}
              alt="Platform Access"
              style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />

            <Typography variant="body1" sx={{ mb: 2 }}>
              Access 300+ curated DSA questions and track your progress on the leaderboard.
            </Typography>

            <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
              ₹{PLATFORM_ACCESS_PRICE}
            </Typography>

            <Button
              variant="contained"
              size="large"
              onClick={() => handleEnrollment(PLATFORM_ACCESS_PRICE, 'Platform Access & structured questions')}
              sx={{ borderRadius: '30px', padding: '12px 24px' }}
            >
              Subscribe Now
            </Button>
          </StyledCard>
        </Grid>

       
        {/* Plan 3 */}
        <Grid item xs={12} md={4}>
          <StyledCard sx={{ position: 'relative', overflow: 'hidden' }}>
            {/* Banner with RGB background */}
            <Box
              sx={{
                backgroundColor: 'rgb(11 102 115)', // Amber color banner
                color: 'white',
                py: 1,
                px: 2,
                textAlign: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                fontWeight: 'bold',
                fontSize: '0.875rem',
                letterSpacing: 1
              }}
            >
              Starting on 20th January 2025
            </Box>

            <Typography variant="h6" fontWeight="bold" sx={{ mt: 3, mb: 3 }}>
              30-Day Level 1 DSA Guidance for Revision
            </Typography>
            
            <motion.img
              src={dsa3}
              alt="Revision Course"
              style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />
            
            <Typography variant="body1" sx={{ mb: 2 }}>
              All features of Level 1 in just 30 days, with 150 questions to solve.
            </Typography>

            <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
              ₹{DSA_REVISE_LEVEL_1_PRICE}
            </Typography>

            <Button
              variant="contained"
              size="large"
              onClick={() => handleEnrollment(DSA_REVISE_LEVEL_1_PRICE, 'DSA Guidance Revision Level 1')}
              sx={{ borderRadius: '30px', padding: '12px 24px' }}
            >
              Enroll Now
            </Button>
          </StyledCard>
        </Grid>
        
        {/* Plan 2 */}
        <Grid item xs={12} md={4}>
          <StyledCard sx={{ position: 'relative', overflow: 'hidden' }}>
            <Box
              sx={{
                backgroundColor: 'rgb(11 102 115)',
                color: 'white',
                py: 1,
                px: 2,
                textAlign: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                fontWeight: 'bold',
                fontSize: '0.875rem',
                letterSpacing: 1
              }}
            >
              Starting on 20th January 2025
            </Box>
            <Typography variant="h6" fontWeight="bold" sx={{ mt: 3, mb: 3 }}>
              Complete DSA Guidance Course (Level 1)
            </Typography>
            <motion.img
              src={dsa2}
              alt="Complete Course"
              style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />
            <Typography variant="body1" sx={{ mb: 2 }}>
              Unlock all features, including contests, mock interviews, and doubt sessions.
              Duration: 2 months
            </Typography>
            <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
              ₹{DSA_COMPLETE_LEVEL_1_PRICE}
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => handleEnrollment(DSA_COMPLETE_LEVEL_1_PRICE, 'Complete DSA Course Level 1')}
              sx={{ borderRadius: '30px', padding: '12px 24px' }}
            >
              Enroll Now
            </Button>
          </StyledCard>
        </Grid>
      </Grid>

      {/* Curriculum Topics Section */}
      <Box id="benefits" sx={{ py: 3 }}>
        <Typography variant="h5" textAlign="center" fontWeight="bold" gutterBottom>
          Topics Covered in Carriculum
        </Typography>
        <Divider sx={{ my: 4 }} />

        <Grid container spacing={2} sx={{ padding: 2 }}>
          {topics.map((topic, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <FeatureItem variant="body1">📌 {topic}</FeatureItem>
            </Grid>
          ))}
        </Grid>
      </Box>


      {/* Course Benefits */}
      <Box sx={{ py: 3, borderRadius: '15px' }}>
        <Typography variant="h5" textAlign="center" fontWeight="bold" gutterBottom>
          What You’ll Get in the Complete Course - Level 2
        </Typography>
        <Divider sx={{ my: 4 }} />
        <Grid container spacing={4} sx={{ padding: 2 }}>
          {[
            '💡 Daily Problem Solving: 5 problems per day (600 total)',
            '📊 Weekly Contests: Test yourself with curated challenges',
            '👨‍🏫 Doubt Sessions: Once a week from 9-12 PM',
            '🎯 Mock Interviews: Practice real-world interview scenarios',
            '🏅 Leaderboard: Track your progress and compete with peers',
            '📋 Resume Guidance: Tips to create an impressive tech resume',
            '🛠️ Day wise tracker for your complete journey',
            '🛠️ Live coding room to solve the problems with your body',
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <FeatureItem variant="body1">✅ {feature}</FeatureItem>
            </Grid>
          ))}
        </Grid>



        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Box
            sx={{
              width: { xs: '90%', sm: '450px' }, // Responsive width
              maxWidth: '100vw', // Prevents horizontal overflow
              padding: '20px',
              bgcolor: theme.palette.mode === 'dark' ? '#333' : '#fff',
              borderRadius: '10px',
              boxShadow: 24,
              margin: 'auto',
              marginTop: '7%',
              position: 'relative',
              maxHeight: '90vh', // Limits height to 90% of viewport height
              overflowY: 'auto', // Enables vertical scrolling when content overflows
            }}
          >
            <IconButton
              onClick={() => setModalOpen(false)}
              sx={{ position: 'absolute', top: '10px', right: '10px' }}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
              { selectedSection }
            </Typography>

            <Grid container spacing={2}>

              {/* Name */}
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Grid>

              {/* Contact Number */}
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Contact Number"
                  variant="outlined"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  required
                />
              </Grid>              

              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  onClick={handlePayment}
                  disabled={!contactNumber || !name || !email}
                  sx={{ width: '100%', bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}
                >
                  Pay {amount} INR to Confirm
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal open={confirmationModalOpen} onClose={() => setConfirmationModalOpen(false)}>
          <Box
            sx={{
              width: '450px',
              padding: '30px',
              bgcolor: 'background.paper',
              borderRadius: '15px',
              boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
              margin: 'auto',
              marginTop: '10%',
              position: 'relative',
              textAlign: 'center',
            }}
          >
            {/* Close Icon */}
            <IconButton
              onClick={() => setConfirmationModalOpen(false)}
              sx={{
                position: 'absolute',
                top: '15px',
                right: '15px',
                bgcolor: 'grey.100',
                '&:hover': {
                  bgcolor: 'grey.300',
                },
                p: 1,
                borderRadius: '50%',
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* Success Icon */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <Box
                sx={{
                  bgcolor: 'success.light',
                  color: 'success.main',
                  p: 2,
                  borderRadius: '50%',
                  display: 'inline-flex',
                }}
              >
                <CheckCircleIcon fontSize="large" />
              </Box>
            </Box>

            {/* Title */}
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
              Payment Successful!
            </Typography>

            {/* Subtitle */}
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              You will receive a confirmation email shortly, and your interview will be scheduled soon.
            </Typography>

            {/* Related Content */}
            <Box
              sx={{
                bgcolor: 'grey.100',
                borderRadius: '10px',
                p: 2,
                mb: 4,
                textAlign: 'left',
              }}
            >
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
                Looking for something existing ?
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body2" component={Link} to="/contests" sx={{ color: 'primary.main', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  Participate in coding contests
                </Typography>
              </Box>
            </Box>

            {/* Solve Problems Button */}
            <Button
              variant="contained"
              onClick={() => navigate('/problems')}
              sx={{
                width: '100%',
                bgcolor: 'primary.main',
                py: 1.5,
                mb: 2,
                borderRadius: '8px',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              Solve Coding Problems
            </Button>

            {/* Close Button */}
            <Button
              variant="outlined"
              onClick={() => setConfirmationModalOpen(false)}
              sx={{
                width: '100%',
                borderColor: 'primary.main',
                color: 'primary.main',
                py: 1.5,
                borderRadius: '8px',
                '&:hover': {
                  borderColor: 'primary.dark',
                  color: 'primary.dark',
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Modal>

        <Modal open={failedPaymentModalOpen} onClose={() => setFailedPaymentModalOpen(false)}>
          <Box
            sx={{
              width: '450px',
              padding: '30px',
              bgcolor: 'background.paper',
              borderRadius: '15px',
              boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
              margin: 'auto',
              marginTop: '10%',
              position: 'relative',
              textAlign: 'center',
            }}
          >
            {/* Close Icon */}
            <IconButton
              onClick={() => setFailedPaymentModalOpen(false)}
              sx={{
                position: 'absolute',
                top: '15px',
                right: '15px',
                bgcolor: 'grey.100',
                '&:hover': {
                  bgcolor: 'grey.300',
                },
                p: 1,
                borderRadius: '50%',
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* Error Icon */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <Box
                sx={{
                  bgcolor: 'error.light',
                  color: 'error.main',
                  p: 2,
                  borderRadius: '50%',
                  display: 'inline-flex',
                }}
              >
                <ErrorIcon fontSize="large" />
              </Box>
            </Box>

            {/* Title */}
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
              Payment Failed
            </Typography>

            {/* Subtitle */}
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              Unfortunately, your payment could not be processed at this time. Please try again or contact support for assistance.
            </Typography>

            {/* Related Content */}
            <Box
              sx={{
                bgcolor: 'grey.100',
                borderRadius: '10px',
                p: 2,
                mb: 4,
                textAlign: 'left',
              }}
            >
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
                Possible Reasons:
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  - Insufficient funds in your account.
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  - Invalid card details or expired card.
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  - Payment gateway issues.
                </Typography>
              </Box>
            </Box>

            {/* Retry Payment Button */}
            <Button
              variant="contained"
              onClick={() => handleRetryPayment()} // Implement the function to retry payment
              sx={{
                width: '100%',
                bgcolor: 'error.main',
                py: 1.5,
                mb: 2,
                borderRadius: '8px',
                '&:hover': {
                  bgcolor: 'error.dark',
                },
              }}
            >
              Retry Payment
            </Button>

            {/* Close Button */}
            <Button
              variant="outlined"
              onClick={() => setFailedPaymentModalOpen(false)}
              sx={{
                width: '100%',
                borderColor: 'primary.main',
                color: 'primary.main',
                py: 1.5,
                borderRadius: '8px',
                '&:hover': {
                  borderColor: 'primary.dark',
                  color: 'primary.dark',
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Modal>


      </Box>
    </Container>
  );
};

export default DSACoursePage;
