import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button, Typography, Tag, Row, Col, Divider, Spin, message, Space, Alert } from 'antd';
import {
  TrophyOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  UserOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { Modal } from 'antd';
import proxy from '../../api/axios';
import { formatDateTime } from '../../common/utils';
import ContestResult from '../ContestResult';

const { Title, Text, Paragraph } = Typography;

const ContestInfoPage = () => {
  const { contestId } = useParams();
  const navigate = useNavigate();

  const [contest, setContest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    const fetchContestDetails = async () => {
      try {
        const { data } = await proxy.get(`/contests/details/${contestId}`);
        const contestDetails = {
          ...data.contest          
        }
        contestDetails.participationCount = data.participationCount
        contestDetails.isUserParticipated = data.isUserParticipated
        contestDetails.isTimeRemaining = data.isTimeRemaining
        setContest(contestDetails);
      } catch (error) {
        message.error('Failed to fetch contest details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchContestDetails();
  }, [contestId]);

  useEffect(() => {
    let interval;
  
    const updateCountdown = () => {
      if (contest && contest.startTime) {
        const startTime = new Date(contest.startTime).getTime();
        const currentTime = new Date().getTime();
        const timeDiff = startTime - currentTime;
  
        if (timeDiff > 0) {
          const days = Math.floor(timeDiff / (24 * 3600 * 1000));
          const hours = Math.floor((timeDiff % (24 * 3600 * 1000)) / (3600 * 1000));
          const minutes = Math.floor((timeDiff % (3600 * 1000)) / (60 * 1000));
          const seconds = Math.floor((timeDiff % (60 * 1000)) / 1000);
  
          setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        } else {
          setTimeRemaining('Contest Started');
          clearInterval(interval);
        }
      }
    };
  
    interval = setInterval(updateCountdown, 1000);
  
    return () => clearInterval(interval);
  }, [contest]);
  

  const handleStartNow = async () => {
    Modal.confirm({
      title: 'Start Contest Confirmation',
      content: `Are you sure you want to ${contest.isUserParticipated ? 'resume' : 'start'} this contest?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          if (!contest.isUserParticipated) {
            await proxy.post(`/contests/join`, { id: contestId });
          }
          message.success(`Contest ${contest.isUserParticipated ? 'resumed' : 'started'} successfully!`);
          navigate(`/contests/${contestId}/live`);
        } catch (error) {
          message.error('Failed to start the contest. Please try again.');
        }
      },
      onCancel() {
        console.log('User cancelled the contest start action');
      },
    });
  };

  const isContestActive = (startTime, endTime) => {
    const currentTime = new Date();
    const contestStartTime = new Date(startTime);
    const contestEndTime = new Date(endTime);
    return contestStartTime <= currentTime && contestEndTime >= currentTime;
  };

  const isContestCompleted = (endTime) => {
    const currentTime = new Date();
    const contestEndTime = new Date(endTime);
    return contestEndTime < currentTime;
  };

  const getContestStatus = (contest) => {
    const currentTime = new Date();
    if (currentTime < new Date(contest.startTime)) {
      return 'Upcoming';
    }
    if (isContestActive(contest.startTime, contest.endTime) && contest.status !== 'Already Submitted' && contest.status !== 'Time Expired') {
      return 'In Progress';
    }
    if (isContestCompleted(contest.endTime) || contest.status === 'Already Submitted' || contest.status === 'Time Expired') {
      return 'Completed'; 
    }
    return 'Unknown';
  };

  if (loading) {
    return (
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (!contest) {
    return (
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Alert
          message="Contest not found"
          description="We couldn't find the contest you're looking for.\n Please check the URL or return to the contests page."
          type="error"
          showIcon
        />
        <Button
          type="primary"
          style={{ marginTop: '20px', marginLeft: '20px' }}
          onClick={() => navigate('/contests')}
        >
          Back to Contests
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: '100vh',
        background: '#f9f9f9',
        padding: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <Card
        style={{
          maxWidth: '1000px',
          width: '100%',
          borderRadius: '12px',
          overflow: 'hidden',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          background: 'white',
        }}
        bodyStyle={{ padding: '30px' }}
      >
        {/* Banner Section */}
        <div
          style={{
            background: 'linear-gradient(to right, rgb(41, 128, 185), rgb(109, 213, 250))',
            color: 'white',
            padding: '20px',
            textAlign: 'center',
            borderRadius: '12px 12px 0 0',
            position: 'relative',
          }}
        >
          <TrophyOutlined style={{ fontSize: '38px', marginBottom: '10px' }} />
          <Title level={4} style={{ color: 'white', margin: 0 }}>
            {contest.title}
          </Title>
          
          <Text style={{ fontSize: '14px', opacity: 0.9 }}>
            Take on the challenge and prove your skills!
          </Text>
        </div>

        {/* Contest Details Section */}
        <Space direction="vertical" size="large" style={{ width: '100%', marginTop: '20px' }}>
          {/* Status and Duration */}
          {getContestStatus(contest) === 'Upcoming' && timeRemaining && (
          <div style={{ fontSize: '16px', color: 'rgb(1, 110, 153)' }}>
            Starts In: {timeRemaining}
          </div>
        )}
          <Row justify="space-between" align="middle">
            <Col>
              <Tag color={getContestStatus(contest) === 'Upcoming' ? 'blue' : getContestStatus(contest) === 'In Progress' ? 'green' : 'red'}>
                {getContestStatus(contest)}
              </Tag>
            </Col>
            <Col>
              <Text style={{ fontWeight: 'bold', color: '#595959' }}>Duration: {contest.duration} mins</Text>
            </Col>
          </Row>

          {/* Dates and Participants */}
          <Row gutter={16}>
            <Col span={12}>
              <Text style={{ fontSize: '14px', color: '#595959' }}>
                <ClockCircleOutlined /> Starts: {formatDateTime(contest.startTime).date} at{' '}
                {formatDateTime(contest.startTime).time}
              </Text>
            </Col>
            <Col span={12}>
              <Text style={{ fontSize: '14px', color: '#595959' }}>
                <CalendarOutlined /> Ends: {formatDateTime(contest.endTime).date} at{' '}
                    {formatDateTime(contest.endTime).time}
              </Text>
            </Col>
            <Col span={12}>
              <Text style={{ fontSize: '14px', color: '#595959' }}>
                <UserOutlined /> Participants: {contest.participationCount}
              </Text>
            </Col>
          </Row>

          {/* <Divider /> */}

          {/* Contest Description */}
          <div>
            <Title level={5}>Description</Title>
            <Paragraph style={{ color: '#595959' }}>
              {contest.description || 'This contest challenges your skills in problem-solving, algorithm design, and efficiency. Do you have what it takes to be at the top?'}
            </Paragraph>
          </div>

          {/* <Divider /> */}

          {/* Rules */}
          <div>
            <Title level={5}>Contest Rules</Title>
            <ul style={{ paddingLeft: '20px', color: '#595959' }}>
              <li>Ensure solutions are submitted before the deadline.</li>
              <li>Plagiarism results in disqualification.</li>
              <li>Maintain a stable internet connection during participation.</li>
            </ul>
          </div>

          {/* <Divider /> */}

          {/* FAQ Section */}
          <div>
            <Title level={5}>FAQs</Title>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text strong>Q: Can I pause the contest?</Text>
              <Text>A: No, the timer continues once the contest is started.</Text>

              <Text strong>Q: Will solutions be shared?</Text>
              <Text>A: Yes, solutions will be made available post-contest.</Text>
            </Space>
          </div>

          {/* <Divider /> */}

          {/* Bottom Start Button */}
          {(getContestStatus(contest) === 'In Progress' && (!contest.isUserParticipated || contest.isTimeRemaining)) && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Button
                type="primary"
                shape="round"
                size="large"
                style={{
                  background: 'linear-gradient(to right, #34d399, #3b82f6)',
                  border: 'none',
                  fontWeight: 'bold',
                  padding: '0 40px',
                }}
                onClick={handleStartNow}
              >
                <PlayCircleOutlined />
                { contest.isTimeRemaining ? 'Resume Contest' : 'Start Contest Now' }
              </Button>
            </div>
          )}

          {getContestStatus(contest) === 'Completed' && <ContestResult contestId={contestId} />}
        </Space>
      </Card>
    </div>
  );
};

export default ContestInfoPage;
