import React, { useEffect, useState } from 'react';
import {
  Box, Button, TextField, Typography, Select, MenuItem, IconButton, Modal, Checkbox, FormControlLabel, Chip,
  CircularProgress,
  Paper
} from '@mui/material';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DeleteIcon from '@mui/icons-material/Delete';
import MonacoEditor from '@monaco-editor/react';
import { toast } from 'react-toastify';
import { defaultCode } from '../../common/constants';
import { useParams, useNavigate } from 'react-router-dom';
import { getToken, runCode } from '../../common/utils';
import proxy from '../../api/axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Typography as TP} from 'antd';
const { Text } = TP;

const ProblemFormPage = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const navigate = useNavigate();
  
  const [title, setTitle] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [description, setDescription] = useState('Description');
  const [difficulty, setDifficulty] = useState('easy');
  const [constraints, setConstraints] = useState('');
  const [inputFormat, setInputFormat] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  const [testCases, setTestCases] = useState([{ input: '', output: '', explanation: '', isSample: true }]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [level, setLevel] = useState(1); // New: Level
  const [displayOrder, setDisplayOrder] = useState(0); // New: Level
  const [javaOutput, setJavaOutput] = useState('');
  const [pythonOutput, setPythonOutput] = useState('');
  const [cppOutput, setCppOutput] = useState('');
  const [jsOutput, setJsOutput] = useState('');
  const [section, setSection] = useState(''); // New: Section

  const [topics, setTopics] = useState([]); // New: Topics

  const [topicInput, setTopicInput] = useState(''); // New: Topic input

  // Language wrappers
  const [wrapperCode, setWrapperCode] = useState(defaultCode);
  const [isSaving, setIsSaving] = useState(false);

  // Fetch problem details for editing
  useEffect(() => {
    if (slug) {
      const fetchProblemDetails = async () => {
        try {
          const response = await proxy.get(`/problems/admin/${slug}`);
          const problem = response.data;

          // Populate fields with problem details
          setTitle(problem.title);
          setDescription(problem.description);
          setDifficulty(problem.difficulty);
          setConstraints(problem.constraints);
          setInputFormat(problem.inputFormat);
          setOutputFormat(problem.outputFormat);
          setTestCases(problem.testCases || [{ input: '', output: '', explanation: '', isSample: false }]);
          setWrapperCode(problem.wrapperCode || defaultCode);
          setVideoLink(problem.videoLink);
          setLevel(problem.level); // Set the level
          setSection(problem.section); // Set the section
          setTopics(problem.topics); // Set topics
          // setTopics(['map','hashmap','set', 'heap']); // Set topics
          setDisplayOrder(problem.displayOrder);
        } catch (error) {
          toast.error('Error fetching problem details');
          console.error(error);
        }
      };

      fetchProblemDetails();
    }
  }, [slug]);

  useEffect(()=>{
    const fetchNextDisplayOrder = async () => {
      try {
        const encodedSection = encodeURIComponent(section);
        const encodedLevel = encodeURIComponent(level);
        const response = await proxy.get(`/problems/next-display-order/?section=${encodedSection}&level=${encodedLevel}`);
        setDisplayOrder(response.data?.nextDisplayOrder || 0);
      } catch(err){
        console.log('Error fetching in next display order: ', err);
        
      }
    }
    if(!slug)
      fetchNextDisplayOrder();
  },[level,section]);

  // Handlers for Test Cases
  const handleAddTestCase = () => {
    setTestCases([...testCases, { input: '', output: '', explanation: '', isSample: false }]);
  };

  const handleRemoveTestCase = (index) => {
    const updatedTestCases = testCases.filter((_, i) => i !== index);
    setTestCases(updatedTestCases);
  };

  const handleTestCaseChange = (index, field, value) => {
    const updatedTestCases = [...testCases];
    updatedTestCases[index][field] = value;
    setTestCases(updatedTestCases);
  };

  const handleSampleTestCaseChange = (index) => {
    const updatedTestCases = [...testCases];
    updatedTestCases[index].isSample = !updatedTestCases[index].isSample;
    setTestCases(updatedTestCases);
  };

  // Preview Handler
  const handlePreviewOpen = () => setPreviewOpen(true);
  const handlePreviewClose = () => setPreviewOpen(false);

  // Handle language-specific wrapper code changes
  const handleWrapperCodeChange = (language, value) => {
    setWrapperCode({
      ...wrapperCode,
      [language]: value,
    });
  };

  const checkDefaultCodes = async () => {
    let status = true;
    setJavaOutput(undefined);
    setCppOutput(undefined);
    setPythonOutput(undefined);
    setJsOutput(undefined);
    
    const javaResult =  await runCode(wrapperCode['java'], 'java', {input: testCases[0].input});
    if(javaResult.status!=='Accepted'){
      setJavaOutput(javaResult.message);
      toast.error('Java code have some error please check');
      status = false; return status;
    }
    const cppResult = await runCode(wrapperCode['cpp'], 'cpp', {input: testCases[0].input});
    if(cppResult.status!=='Accepted'){
      setCppOutput(cppResult.message);
      toast.error('C++ code have some error please check');
      status = false; return status;
    }
    const pythonResult = await runCode(wrapperCode['python'], 'python', {input: testCases[0].input});
    if(pythonResult.status!=='Accepted'){
      setPythonOutput(pythonResult.message);
      toast.error('Python code have some error please check');
      status = false; return status;
    }
    const jsResult = await runCode(wrapperCode['javascript'], 'javascript', {input: testCases[0].input});
    if(jsResult.status!=='Accepted'){
      setJsOutput(jsResult.message);
      toast.error('Javascript code have some error please check');
      status = false; return status;
    }
    return status;
  }

  const handleSubmitProblem = async () => {
    const problemData = {
      title,
      description,
      difficulty,
      constraints,
      inputFormat,
      outputFormat,
      testCases,
      wrapperCode,
      videoLink,
      isPublished: false,
      level,
      section,
      topics, 
      displayOrder
    };

    if (!title || !description){
      toast.error('Problem title and description should be there');
      return;
    } else if(testCases.filter(testCase=>testCase.input)?.length===0){
      toast.error('There should be atleast a test case added');
      return;
    }
    try {
      setIsSaving(true);
      // const defaultCodeStatus = await checkDefaultCodes();
      // if(!defaultCodeStatus){
      //   return;
      // }
      if (slug) {
        // Update existing problem
        const { data } = await proxy.put(`/problems/${slug}`, problemData, {headers: { 'Authorization': `Bearer ${getToken()}` }});
        toast.success('Problem updated successfully');
        navigate(`/admin/problems/${data.slug}`);
      } else {
        // Create new problem
        const { data } = await proxy.post('/problems', problemData, {headers: { 'Authorization': `Bearer ${getToken()}` }});
        toast.success('Problem created successfully');
        navigate(`/admin/problems/${data.slug}`);
      }
    } catch (error) {
      toast.error('Error creating/updating problem');
      console.error('Error submitting problem', error);
    } finally {
      setIsSaving(false);
    }
  };

  // Handlers for Topics
  const handleAddTopic = () => {
    if (topicInput.trim() && !topics.includes(topicInput.trim())) {
      setTopics([...topics, topicInput.trim()]);
      setTopicInput('');
    }
  };

  const handleRemoveTopic = (topicToRemove) => {
    setTopics(topics.filter((topic) => topic !== topicToRemove));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
  };
  const sections = [
    'Getting Started',
    'Patterns',
    'Number System',
    'Arrays',
    'Searching & Sorting',
    '2-D Array',
    'Strings',
    'Recursion & Backtracking',
    'Time & Space Complexity',
    'Stack and Queues',
    'Linked List',
    'Generic Trees',
    'Binary Trees',
    'Binary Search Trees',
    'HashMap & Heap',
    'Graph',
    'Dynamic Programming',
    'Trie',
    'Contest'
  ]

  return (
    <Box sx={{ width: '90%', margin: 'auto', textAlign: 'left', padding: '20px' }}>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => navigate('/admin/manage-problems')} 
        sx={{ mb: 1 }}
      >
        All Problems
      </Button>
      <Typography variant="h5" gutterBottom style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {slug ? 'Edit Problem' : 'Create Problem'}
        <Box>
          <Button onClick={handlePreviewOpen} variant="outlined" sx={{ marginRight: '10px' }}>
            Preview
          </Button>
          <Button disabled={isSaving} onClick={handleSubmitProblem} variant="contained" color="success">
            {isSaving && <CircularProgress style={{ marginRight: 10 }} size={20} color="inherit" />}
            {slug ? 'Update Draft' : 'Save Draft'}
          </Button>
        </Box>
      </Typography>
      {/* Level, Section, and Topics in a single row */}
      <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '20px' }}>
        {/* Level Selector */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" gutterBottom>
            Level
          </Typography>
          <Select
            fullWidth
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            margin="normal"
          >
            <MenuItem value={1}>Level 1</MenuItem>
            <MenuItem value={2}>Level 2</MenuItem>
          </Select>
        </Box>

        {/* Section Selector */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" gutterBottom>
            Section
          </Typography>
          <Select
            fullWidth
            value={section}
            onChange={(e) => setSection(e.target.value)}
            margin="normal"
          >
            {
              sections.map(section=>(
                <MenuItem key={section} value={section}>{section}</MenuItem>
              ))
            }
          </Select>
        </Box>

        {/* Topics Input */}
        <Box sx={{ flex: 2, minHeight: '147px', marginTop: '50px' }}>
          <Typography variant="h6" gutterBottom>
            Topics
          </Typography>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <TextField
              label="Add Topic"
              value={topicInput}
              onChange={(e) => setTopicInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleAddTopic()}
              fullWidth
            />
            <Button onClick={handleAddTopic} variant="contained">
              Add
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
            {topics.map((topic, index) => (
              <Chip
                key={index}
                label={topic}
                onDelete={() => handleRemoveTopic(topic)}
                color="primary"
                sx={{ marginBottom: '10px' }}
              />
            ))}
          </Box>
        </Box>
      </Box>
      {/* New: Order Input */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Display Order</Typography>
        <TextField
          type="number"
          fullWidth
          value={displayOrder}
          onChange={(e) => setDisplayOrder(parseInt(e.target.value, 10))}
          placeholder="Order number"
        />
      </Box>

      {/* Problem Title */}
      <TextField
        label="Problem Title"
        fullWidth
        margin="normal"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      
      {/* Video Link */}
      <TextField
        label="Video Link"
        fullWidth
        margin="normal"
        value={videoLink}
        onChange={(e) => setVideoLink(e.target.value)}
      />
      <Typography variant="h6" gutterBottom>Problem Description </Typography>
      <CKEditor
        editor={ClassicEditor}
        data={description}
        onReady={(editor) => console.log('Editor is ready!', editor)}
        onChange={(event, editor) => setDescription(editor.getData())}
        onBlur={(event, editor) => console.log('Blur.', editor)}
        onFocus={(event, editor) => console.log('Focus.', editor)}
      />

      {/* Difficulty Selector */} <br/>
      <Typography variant="h6" gutterBottom>
        Difficulty
      </Typography>
      <Select
        fullWidth
        value={difficulty}
        onChange={(e) => setDifficulty(e.target.value)}
        margin="normal"
      >
        <MenuItem value="easy">Easy</MenuItem>
        <MenuItem value="medium">Medium</MenuItem>
        <MenuItem value="hard">Hard</MenuItem>
      </Select>
      <br/><br/>  
      <Typography variant="h6" gutterBottom>
        Constraints
      </Typography>
      {/* Constraints Field */}
      <CKEditor
        editor={ClassicEditor}
        data={constraints}
        onReady={(editor) => console.log('Editor is ready!', editor)}
        onChange={(event, editor) => setConstraints(editor.getData())}
        onBlur={(event, editor) => console.log('Blur.', editor)}
        onFocus={(event, editor) => console.log('Focus.', editor)}
      />
      {/* Input Format Field */}
      <TextField
        label="Input Format"
        fullWidth
        multiline
        rows={3}
        margin="normal"
        value={inputFormat}
        onChange={(e) => setInputFormat(e.target.value)}
      />

      {/* Output Format Field */}
      <TextField
        label="Output Format"
        fullWidth
        multiline
        rows={3}
        margin="normal"
        value={outputFormat}
        onChange={(e) => setOutputFormat(e.target.value)}
      />

      {/* Test Cases Section */}
      <Typography variant="h6" gutterBottom>
        Test Cases
      </Typography>
      {testCases.map((testCase, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <TextField
            label={`Input #${index + 1}`}
            multiline
            rows={5}
            fullWidth
            value={testCase.input}
            onChange={(e) => handleTestCaseChange(index, 'input', e.target.value)}
            margin="normal"
            sx={{
              marginRight: '10px',
              '& .MuiInputBase-root': {
                resize: 'both',  // Enables resizing from corners
                overflow: 'auto', // Prevents overflow issues during resizing
              },
            }}
          />
          <TextField
            label={`Output #${index + 1}`}
            multiline
            rows={5}
            fullWidth
            value={testCase.output}
            onChange={(e) => handleTestCaseChange(index, 'output', e.target.value)}
            margin="normal"
            sx={{
              marginRight: '10px',
              '& .MuiInputBase-root': {
                resize: 'both',  // Enables resizing from corners
                overflow: 'auto', // Prevents overflow issues during resizing
              },
            }}
          /> 
          <TextField
            label={`Explanation #${index + 1}`}
            multiline
            rows={5}
            fullWidth
            value={testCase.explanation}
            onChange={(e) => handleTestCaseChange(index, 'explanation', e.target.value)}
            margin="normal"
            sx={{
              marginRight: '10px',
              '& .MuiInputBase-root': {
                resize: 'both',  // Enables resizing from corners
                overflow: 'auto', // Prevents overflow issues during resizing
              },
            }}
          /> 
          <FormControlLabel
            control={
              <Checkbox
                checked={testCase.isSample}
                onChange={() => handleSampleTestCaseChange(index)}
                sx={{ width: '30%', ml:1 }}
              />
            }
            label="Sample"
          />
          <IconButton onClick={() => handleRemoveTestCase(index)} color="error" sx={{ marginTop: '10px' }}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button onClick={handleAddTestCase} variant="contained" sx={{ marginBottom: '20px' }}>
        Add Test Case
      </Button>

      {/* Wrapper Code Section for each language */}
 
      <h3>Initial Code</h3><hr/>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} sx={{ mt:2 }}>
        {/* Java Editor */}
        <Box>
          <Typography variant="body" gutterBottom>
            Java
          </Typography>
          <MonacoEditor
            height="350px"
            language="java"
            value={wrapperCode.java}
            onChange={(value) => handleWrapperCodeChange('java', value)}
          />
          {(javaOutput &&
            <div
              className="output-container"
              style={{
                marginTop: '10px',
                padding: '10px',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                backgroundColor: '#f7f7f7',
              }}
            >
              <Paper sx={{ padding: '10px', whiteSpace: 'pre-wrap'}}>
                <Text>{javaOutput}</Text>
              </Paper>
            </div>
          )}
        </Box>
        {/* C++ Editor */}
        <Box>
          <Typography variant="body" gutterBottom>
            C++
          </Typography>
          <MonacoEditor
            height="350px"
            language="cpp"
            value={wrapperCode.cpp}
            onChange={(value) => handleWrapperCodeChange('cpp', value)}
          />
          {(cppOutput &&
            <div
              className="output-container"
              style={{
                marginTop: '10px',
                padding: '10px',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                backgroundColor: '#f7f7f7',
              }}
            >
              <Paper sx={{ padding: '10px', whiteSpace: 'pre-wrap'}}>
                <Text>{cppOutput}</Text>
              </Paper>
            </div>
          )}
        </Box>
        {/* Python Editor */}
        <Box>
          <Typography variant="body" gutterBottom>
            Python
          </Typography>
          <MonacoEditor
            height="350px"
            language="python"
            value={wrapperCode.python}
            onChange={(value) => handleWrapperCodeChange('python', value)}
          />
          {(pythonOutput &&
            <div
              className="output-container"
              style={{
                marginTop: '10px',
                padding: '10px',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                backgroundColor: '#f7f7f7',
              }}
            >
              <Paper sx={{ padding: '10px', whiteSpace: 'pre-wrap'}}>
                <Text>{pythonOutput}</Text>
              </Paper>
            </div>
          )}
        </Box>

        {/* JavaScript Editor */}
        <Box>
          <Typography variant="body" gutterBottom>
            JavaScript
          </Typography>
          <MonacoEditor
            height="350px"
            language="javascript"
            value={wrapperCode.javascript}
            onChange={(value) => handleWrapperCodeChange('javascript', value)}
          />
          {(jsOutput &&
            <div
              className="output-container"
              style={{
                marginTop: '10px',
                padding: '10px',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                backgroundColor: '#f7f7f7',
              }}
            >
              <Paper sx={{ padding: '10px', whiteSpace: 'pre-wrap'}}>
                <Text>{jsOutput}</Text>
              </Paper>
            </div>
          )}
        </Box>
      </Box>

      
      {/* Modal for Preview */}
      <Modal
        open={previewOpen}
        onClose={handlePreviewClose}
        aria-labelledby="preview-modal-title"
        aria-describedby="preview-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" id="preview-modal-title">Problem Preview</Typography>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: description }} />
          <Typography variant="subtitle1">Difficulty: {difficulty}</Typography>
          <Typography variant="subtitle1">Constraints:</Typography>
          <Typography variant="body1">{constraints}</Typography>
          <Typography variant="subtitle1">Input Format:</Typography>
          <Typography variant="body1">{inputFormat}</Typography>
          <Typography variant="subtitle1">Output Format:</Typography>
          <Typography variant="body1">{outputFormat}</Typography>
          <Typography variant="subtitle1">Test Cases:</Typography>
          {testCases.map((testCase, index) => (
            <Typography key={index} variant="body2">
              {`Input: ${testCase.input}, Output: ${testCase.output}, Sample: ${testCase.isSample ? 'Yes' : 'No'}`}
            </Typography>
          ))}
        </Box>
      </Modal>
    </Box>
  );
};

export default ProblemFormPage ;
