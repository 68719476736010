// src/App.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router-dom';
import ProblemsPage from './pages/ProblemsPage';
import ProblemPage from './pages/ProblemPage';
import ProgramsPage from './pages/ProgramsPage';
import LeaderboardPage from './pages/LeaderboardPage';
import ContestsPage from './pages/ContestsPage';
import UserProfilePage from './pages/UserProfilePage';
import { Button, Box } from '@mui/material';
import FeedbackForm from './pages/FeedbackFormModal';
import ProblemFormPage from './pages/admin/ProblemFormPage ';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ManageProblems from './pages/admin/ManageProblems';
import { login } from './redux/userSlice';
import ResetPassword from './components/auth/ResetPassword';
import Footer from './components/Footer';
import ManageContests from './pages/admin/ManageContests';
import LiveContest from './pages/LiveContest';
import ContestResult from './pages/ContestResult';
import ManageUsers from './pages/admin/ManageUsers';
import BookingPage from './pages/BookingPage';
import DSACoursePage from './pages/AllProgramsPage';
import CompilerPage from './pages/CompilerPage';
import ChallengeDetailsPage from './pages/challenges/75HardChallengePage';
import LiveCodingRoom from './pages/live-coding/LiveCodingRoom';
import ContestInfoPage from './pages/contests/ContestInfoPage';

const App = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    if (token && user) {
      dispatch(login(user));
    }
  }, [dispatch]);

  const handleFeedbackOpen = () => setFeedbackOpen(true);
  const handleFeedbackClose = () => setFeedbackOpen(false);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <ToastContainer autoClose={2000}/>

      {/* Root layout with flexbox */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Navbar />

        {/* Main content area with padding to prevent footer overlap */}
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            paddingBottom: '80px', // Ensure content doesn't overlap footer
          }}
        >
          <Routes>
            <Route path="/" element={<ProblemsPage />} />
            <Route path="/problems" element={<ProblemsPage />} />
            <Route path="/problems/:slug" element={<ProblemPage />} />
            <Route path="/admin/problems/:slug" element={<ProblemPage isAdmin={true} />} />
            <Route path="/my-programs" element={<ProgramsPage />} />
            <Route path="/ide/:link" element={<CompilerPage />} />
            <Route path="/ide" element={<CompilerPage />} />
            <Route path="/programs" element={<DSACoursePage />} />
            <Route path="/challenges/75-hard-coding-challenge" element={<ChallengeDetailsPage />} />
            <Route path="/contests" element={<ContestsPage />} />
            <Route path="/contests/details/:contestId" element={<ContestInfoPage />} />
            <Route path="/contests/:contestId/result" element={<ContestResult />} />
            <Route path="/contests/:contestId/live" element={<LiveContest />} />
            <Route path="/leaderboard" element={<LeaderboardPage />} />
            <Route path="/user/:username" element={<UserProfilePage />} />
            <Route path="/profile" element={<UserProfilePage />} />
            <Route path="/schedule-mock-interviews" element={<BookingPage />} />
            <Route path="/live-coding-room" element={<LiveCodingRoom />} />
            <Route path="/live-coding-room" element={<LiveCodingRoom />} />
            <Route path="/live-coding-room/:roomId" element={<LiveCodingRoom />} />

            {/* Admin Routes */}
            <Route path="/admin/manage-problems" element={<ManageProblems />} />
            <Route path="/admin/create-problem" element={<ProblemFormPage />} />
            <Route path="/admin/manage-contests" element={<ManageContests />} />
            <Route path="/admin/manage-users" element={<ManageUsers />} />
            <Route path="/admin/edit-problem/:slug" element={<ProblemFormPage />} />

            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="*" element={<ProblemsPage />} />
          </Routes>
        </Box>

        {/* Footer with flexShrink to stay at the bottom */}
        <Footer sx={{ flexShrink: 0 }} />

        {/* Feedback button */}
        <Button
          variant="contained"
          color="primary"
          size='small'
          onClick={handleFeedbackOpen}
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
        >
          Feedback
        </Button>

        <FeedbackForm open={isFeedbackOpen} handleClose={handleFeedbackClose} />
      </Box>
    </ThemeProvider>
  );
};

export default App;
