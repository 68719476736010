import React, { useEffect, useState } from 'react';
import { Table, Avatar, Typography, Spin, Card, Row, Col, message, Divider } from 'antd';
import { TrophyOutlined, UserOutlined } from '@ant-design/icons';
import proxy from '../api/axios';

const { Title, Text } = Typography;

const ContestResult = ({ contestId }) => {
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);
        const { data } = await proxy.get(`/contests/${contestId}/result`);
        setParticipants(data.participants || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching contest results:', error);
        message.error('Could not load contest results.');
        setLoading(false);
      }
    };

    fetchResults();
  }, [contestId]);

  const getTrophyColor = (rank) => {
    if (rank === 1) return '#FFD700'; // Gold
    if (rank === 2) return '#C0C0C0'; // Silver
    if (rank === 3) return '#CD7F32'; // Bronze
    return '#1890FF'; // Default
  };

  const LeaderPanel = () => (
    <Row gutter={16} justify="center" style={{ marginBottom: '24px' }}>
      {participants.slice(0, Math.min(3, participants.length)).map((participant, index) => (
        <Col key={participant.user.id} xs={24} sm={8}>
          <Card
            hoverable
            style={{
              textAlign: 'center',
              borderRadius: '12px',
              boxShadow: `0 4px 15px rgba(0, 0, 0, 0.1)`,
              background: `linear-gradient(135deg, ${getTrophyColor(index + 1)} 30%, #ffffff 100%)`,
            }}
          >
            <TrophyOutlined
              style={{
                fontSize: '36px',
                color: getTrophyColor(index + 1),
                marginBottom: '12px',
              }}
            />
            <Avatar
              size={64}
              src={participant.user.profilePicture}
              icon={<UserOutlined />}
              style={{ marginBottom: '12px', border: '2px solid #fff' }}
            />
            <Title level={4}>{participant.user.name}</Title>
            <Text strong>{participant.score} Points</Text>
          </Card>
        </Col>
      ))}
    </Row>
  );

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      render: (_, __, index) => <Text>{index + (participants.length <= 3 ? 1 : 4)}</Text>, // Adjust rank based on leader count
    },
    {
      title: 'Participant',
      dataIndex: 'user',
      key: 'user',
      render: (user) => (
        <Row align="middle" gutter={8}>
          <Col>
            <Avatar src={user.profilePicture} alt={user.name} />
          </Col>
          <Col>
            <Text>{user.name}</Text>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      render: (score) => <Text>{score} Points</Text>,
    },
  ];

  return (
    <div
      style={{
        padding: '40px',
        background: 'linear-gradient(to bottom, #f0f2f5, #ffffff)',
        minHeight: '100vh',
      }}
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spin size="large" />
        </div>
      ) : participants.length > 0 ? (
        <div style={{ maxWidth: '900px', margin: '0 auto' }}>
          <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>
            Contest Results
          </Title>

          {/* Leader Panel */}
          {participants.length > 0 && <LeaderPanel />}

          {/* Divider */}
          {participants.length > 3 && (
            <Divider orientation="center">
              <Text type="secondary">Other Participants</Text>
            </Divider>
          )}

          {/* Results Table */}
          {participants.length > 3 && (
            <Card
              style={{
                borderRadius: '12px',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Table
                dataSource={participants.slice(3).map((p, index) => ({
                  key: p.user.id,
                  rank: index + 4,
                  user: p.user,
                  score: p.score,
                }))}
                columns={columns}
                pagination={false}
                bordered={false}
              />
            </Card>
          )}
        </div>
      ) : (
        <div style={{ textAlign: 'center', padding: '50px' }}>
          <Title level={3}>No participants yet</Title>
          <Text type="secondary">Results will appear here once participants join.</Text>
        </div>
      )}
    </div>
  );
};

export default ContestResult;
