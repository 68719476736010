import React, { useEffect, useRef, useState } from 'react';

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  Box,
  Badge,
  useMediaQuery,
  useTheme,
  TextField,
  Autocomplete,
  CircularProgress,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/userSlice';
import { toggleTheme } from '../redux/themeSlice';
import AuthModal from './auth/AuthModal';
import proxy from '../api/axios';

const Navbar = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInUser = useSelector((state) => state.user.userInfo);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(true);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    dispatch(logout());
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [isLoggedIn]);

  const menuItems = [
    { text: 'Mock Interviews', path: '/schedule-mock-interviews' },
    { text: 'Programs', path: '/programs', badge: true },
    { text: 'Problems', path: '/problems' },
    { text: 'Contests', path: '/contests' },
    { text: 'IDE', path: '/ide' },
  ];

  if (loggedInUser?.isPremiumUser) {
    menuItems.push({ text: 'My Programs', path: '/my-programs' });
  }

  if (!isLoggedIn) {
    menuItems.push({ text: 'SignIn', path: '#', onClick: () => setAuthModalOpen(true) });
  }

  const handleThemeChange = () => {
    dispatch(toggleTheme());
  };

  const debounceTimeout = useRef(null);

  const handleSearchChange = async (event, value) => {
    setSearchInput(value);
    if (value.length < 2) return; // Don't fetch for very short inputs

    // Clear the previous timeout to debounce
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    setLoading(true);

    // Set a new timeout to delay the API call
    debounceTimeout.current = setTimeout(async () => {
      try {
        const response = await proxy.get(`/problems/search?q=${value}`);
        setSearchResults(response.data.problems); // Assuming the API returns a list of problems
      } catch (error) {
        console.error('Error fetching search results:', error);
      } finally {
        setLoading(false);
      }
    }, 300); // Adjust the delay (in ms) as needed
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
          minHeight: '55px',
        }}
      >
        <Toolbar>
          <Typography
            component={Link}
            to={'/'}
            variant="h4"
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
              flexGrow: 1,
              fontWeight: 'bold',
              color: theme.palette.mode === 'dark' ? 'rgb(6 243 234)' : '#3D5AFE',
              textShadow: theme.palette.mode === 'dark'
                ? '2px 2px 4px rgba(0, 0, 0, 0.7)'
                : '2px 2px 4px rgba(150, 150, 150, 0.5)',
              letterSpacing: '0.1em',
              fontFamily: '"Courier New", Courier, monospace',
            }}
          >
            Crafter<span style={{ color: '#FF4081', marginLeft: 5 }}>Hack</span>
          </Typography>
          {/* Search Box */}
          {searchOpen && !isMobile && (
          <Autocomplete
          freeSolo
          size="small"
          options={searchResults}
          getOptionLabel={(option) => option.title || ''}
          loading={loading}
          onInputChange={handleSearchChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search problems..."
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box
              {...props}
              component={Link}
              to={`/problems/${option.slug}`}
              sx={{
                textDecoration: 'none',
                color: theme.palette.text.primary,
                fontSize: '14px',
                '&:hover': { backgroundColor: theme.palette.action.hover },
              }}
            >
              {option.title}
            </Box>
          )}
          sx={{ width: { xs: '80%', sm: 250, marginRight: '20px' } }}
        />
          )}
          {!isMobile ? (
            <Box sx={{ position: 'relative' }}>
              {menuItems.map((item, index) => (
                <Box key={index} sx={{ position: 'relative', display: 'inline-block', mr: 1 }}>
                  <NavLink
                    to={item.path}
                    onClick={item.onClick}
                    style={({ isActive }) => ({
                      color: isActive
                        ? theme.palette.mode === 'dark' ? '#3D5AFE' : '#FF4081'
                        : theme.palette.mode === 'dark' ? '#fff' : '#000',
                      fontWeight: isActive ? 'bold' : 'normal',
                      background: isActive
                        ? theme.palette.mode === 'dark'
                          ? 'rgba(61, 90, 254, 0.1)'
                          : 'rgba(255, 64, 129, 0.1)'
                        : 'inherit',
                      borderRadius: '5px',
                      padding: '4px 5px',
                      textDecoration: 'none',
                      fontSize: '12px',
                    })}
                  >
                    {item.text === 'SignIn' && <LockOpenIcon fontSize='15px' sx={{ mr: 0.5 }} />}
                    {item.text}
                  </NavLink>

                  {item.badge && (
                    <Badge
                      badgeContent="New"
                      color="error"
                      overlap="rectangular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      sx={{
                        position: 'absolute',
                        top: -3,
                        backgroundColor: '#FF4081',
                        color: '#fff',
                        borderRadius: '50%',
                        fontSize: '0.3rem',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
                        animation: 'pulse 1.5s infinite ease-in-out',
                      }}
                    />
                  )}
                </Box>
              ))}
              {isLoggedIn && (
                <>
                  <Button color="inherit" onClick={handleMenu} style={{ fontSize: '10px' }}>
                    <AccountCircle sx={{ width: 20, height: 20, mr: 1 }} />
                    {loggedInUser.name.split(' ')[0]}
                  </Button>

                  <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    {loggedInUser.role === 'admin' && ( // Only show these items if the user is an admin
                      <>
                        <MenuItem style={{ fontSize: '12px' }} component={Link} to='admin/manage-problems' onClick={handleClose}>Manage Problems</MenuItem>
                        <MenuItem style={{ fontSize: '12px' }}  component={Link} to='admin/manage-contests' onClick={handleClose}>Manage Contests</MenuItem>
                        <MenuItem style={{ fontSize: '12px' }}  component={Link} to='admin/manage-users' onClick={handleClose}>Manage Users</MenuItem>
                      </>
                    )}
                    {loggedInUser.role === 'softadmin' && ( // Only show these items if the user is an admin
                      <>
                        <MenuItem style={{ fontSize: '12px' }}  component={Link} to='admin/manage-problems' onClick={handleClose}>Manage Problems</MenuItem>
                      </>
                    )}
                    <MenuItem style={{ fontSize: '12px' }}  component={Link} to={`profile`} onClick={handleClose}>My Profile</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }}  onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          ) : (
            <Button style={{ fontSize: '12px' }} color="inherit" onClick={toggleDrawer(true)}>
              <FormatListBulletedIcon sx={{ ml: 2 }} />
            </Button>
          )}
          <IconButton onClick={handleThemeChange} color="inherit">
            {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              style={{ fontSize: '11px' }}
              button
              component={NavLink}
              to={item.path}
              key={index}
              onClick={item.onClick}
            >
              {item.text}
            </ListItem>
          ))}
          {isLoggedIn && (
            <>
              <Button style={{ fontSize: '11px' }}  color="inherit" onClick={handleMenu}>
                <AccountCircle sx={{ width: 30, height: 30, mr: 1 }} />
                {loggedInUser.name.split(' ')[0]}
              </Button>
              <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {loggedInUser.role === 'admin' && ( // Only show these items if the user is an admin
                  <>
                    <MenuItem style={{ fontSize: '11px' }}  component={Link} to='admin/manage-problems' onClick={handleClose}>Manage Problems</MenuItem>
                    <MenuItem style={{ fontSize: '11px' }}  component={Link} to='admin/manage-contests' onClick={handleClose}>Manage Contests</MenuItem>
                    <MenuItem style={{ fontSize: '11px' }}  component={Link} to='admin/manage-users' onClick={handleClose}>Manage Users</MenuItem>
                  </>
                )}
                {loggedInUser.role === 'softadmin' && ( // Only show these items if the user is an admin
                  <>
                    <MenuItem style={{ fontSize: '11px' }}  component={Link} to='admin/manage-problems' onClick={handleClose}>Manage Problems</MenuItem>
                  </>
                )}
                <MenuItem style={{ fontSize: '11px' }}  component={Link} to={`profile`} onClick={handleClose}>My Profile</MenuItem>
                <MenuItem style={{ fontSize: '11px' }}  onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </List>
      </Drawer>

      <AuthModal open={authModalOpen} onClose={() => setAuthModalOpen(false)} />
    </>
  );
};

export default Navbar;
