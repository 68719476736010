import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, IconButton, InputBase, Divider, Chip, CircularProgress, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import proxy from '../api/axios';
import { Button as Btn } from 'antd';
import { CodeOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { shuffleArray } from '../common/utils';
import ShuffleIcon from '@mui/icons-material/Shuffle'; 
import { Helmet } from 'react-helmet';
// Add these imports at the top of your file
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useDispatch, useSelector } from 'react-redux';
import { showAuth } from '../redux/authSlice';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import { trophyColors } from './LeaderboardPage';
const ProblemsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [problems, setProblems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [filteredProblems, setFilteredProblems] = useState([]);
  const [toppers, setToppers] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedDifficulties, setSelectedDifficulties] = useState([]); // State for difficulty
  const [uniqueTopics, setUniqueTopics] = useState([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const difficulties = ['easy', 'medium', 'hard']; // Difficulty levels
  const toPascalCase = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  
  const fetchToppers = async () => {
    try {
      const response = await proxy.get('/users/top-solvers');
      setToppers(response.data.toppers);
    } catch(error){
      console.log('Error fetching leaderboard', error);
    }
  }

  useEffect(() => {
    const fetchProblems = async () => {
      try {
        const response = await proxy.get('/problems');
        const data = response.data;
        const shuffledProblems = shuffleArray(data);
        setProblems(shuffledProblems);
        setFilteredProblems(shuffledProblems);

        const topicsSet = new Set();
        shuffledProblems?.forEach(problem => {
          problem.topics.forEach(topic => topicsSet.add(topic));
        });
        setUniqueTopics(Array.from(topicsSet));
      } catch (error) {
        setError('Error fetching problems');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProblems();
    fetchToppers();
  }, []);

  useEffect(() => {
    const filtered = problems.filter(problem => {
      const matchesSearch = problem.title.toLowerCase().includes(searchInput.toLowerCase());
      const matchesTopic = selectedTopics.length === 0 || selectedTopics.some(topic =>
        problem.topics.includes(topic)
      );
      const matchesDifficulty = selectedDifficulties.length === 0 || selectedDifficulties.includes(problem.difficulty);
      return matchesSearch && matchesTopic && matchesDifficulty;
    });
    setFilteredProblems(filtered);
  }, [searchInput, selectedTopics, selectedDifficulties, problems]);

  const handleTopicClick = (topic) => {
    setSelectedTopics((prev) =>
      prev.includes(topic) ? prev.filter(t => t !== topic) : [...prev, topic]
    );
  };

  const handleDifficultyClick = (difficulty) => {
    setSelectedDifficulties((prev) =>
      prev.includes(difficulty) ? prev.filter(d => d !== difficulty) : [...prev, difficulty]
    );
  };

  const handlePickForMe = () => {
    // Filter the problems to include only those that are unsolved
    const unsolvedProblems = filteredProblems.filter(problem => !problem.isSolved);
  
    if (unsolvedProblems.length > 0) {
      const randomProblem = unsolvedProblems[Math.floor(Math.random() * unsolvedProblems.length)];
      navigate(`/problems/${randomProblem.slug}`);
    } else {
      alert('You do not have any problem left to solve!');
    }
  };

  const handle75HardDetailsClick = ()=>{
    navigate('/challenges/75-hard-coding-challenge');
  }
  
  const handleLeaderboardClick = ()=>{
    navigate('/leaderboard');
  }
  const handleLiveCodeClick = ()=>{
    navigate('/live-coding-room');
  }

  
  const handleTopperNameClick = (username)=>{
    navigate(`/user/${username}`);
  }
  
  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  return (
  <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
    <Helmet>
      <title>Coding problems | CrafterHack</title>
      <meta
        name="description"
        content={`This is the page for viewing all the coding problems difficulty and topic wise`}
      />
    </Helmet>

    {/* Header Section with Problems Title, 75 Hard Challenges, and Programs Offered */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end', // Aligns content to the right
        alignItems: 'center',
        mb: 2,
        gap: 3,
        flexDirection: { xs: 'column', md: 'row' }, // Stacks items vertically on mobile and tablet
        pr: { xs: 0, md: 2 }, // Padding on the right for smaller screens
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Stacks items vertically on mobile and tablet
          gap: 3,
          width: { xs: '100%', md: 'auto' }, // Full width on mobile
          ml: { xs: 2, md: 0 }, // Left margin for spacing on mobile
        }}
      >
        {/* 75 Hard Challenges Section */}
        <Paper
          sx={{
            p: { xs: 1, md: 1.5 }, // Smaller padding on mobile
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 1.5, md: 3 }, // Adjust gap for smaller screens
            borderRadius: '12px', // Slightly smaller radius for compact design
            background: theme.palette.mode === 'dark' 
              ? '#333' 
              : 'linear-gradient(135deg, #e0f7fa 30%, #f1f8e9 100%)',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)', // Softer shadow
            position: 'relative',
            transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': { boxShadow: '0 6px 18px rgba(0, 0, 0, 0.15)' },
            width: { xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(50% - 16px)' }, // Adjust for different screens
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 6,
              right: 6,
              px: 1,
              py: 0.3,
              backgroundColor: '#3f51b5',
              color: 'white',
              borderRadius: '10px',
              fontSize: '9px', // Smaller font size
              fontWeight: 'bold',
              textAlign: 'center',
              letterSpacing: '0.4px',
            }}
          >
            Live now
          </Box>

          <EmojiEventsIcon sx={{ color: '#ff9800', fontSize: { xs: 35, md: 50 } }} /> {/* Icon size responsive */}

          <Box
            sx={{ 
              flexGrow: 1, 
              cursor: 'pointer', 
              overflow: 'hidden' // Handle content overflow on smaller screens
            }}
            onClick={handle75HardDetailsClick}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '12px', md: '16px' }, // Smaller font on mobile
                color: theme.palette.mode === 'dark' ? '#fff' : '#2e7d32',
                mb: 0.3,
              }}
            >
              75 Hard Coding Challenge 🚀
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: theme.palette.mode === 'dark' ? 'lightgrey' : '#424242',
                fontSize: { xs: '10px', md: '11px' }, // Adjust font size
                maxWidth: '350px',
              }}
            >
              Solve 75 problems in 75 days to become a coding master. Sharpen your skills, stay motivated, and track your progress!
            </Typography>
          </Box>

          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: '#137274',
                color: 'white',
                mt: { xs: 1, md: 2 }, // Adjust margin for smaller screens
                fontSize: { xs: '12px', md: '13px' }, // Button font size responsive
                padding: { xs: '2px 6px', md: '4px 5px' }, // Adjust padding
                '&:hover': { backgroundColor: '#388e3c' },
                minWidth: '100px', // Reduced button width
              }}
              onClick={handle75HardDetailsClick}
            >
              Start Now 💪
            </Button>
          </Box>
        </Paper>


        {/* Programs Offered Section */}
        <Paper
          sx={{
            p: { xs: 1, md: 1.5 }, // Adjust padding for mobile
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 1.5, md: 3 }, // Responsive gap
            borderRadius: '12px', // Compact radius for cleaner look
            background: theme.palette.mode === 'dark' 
              ? '#424242' 
              : 'linear-gradient(135deg, #ffecb3 30%, #e1bee7 100%)',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)', // Softer shadow
            position: 'relative',
            transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': { boxShadow: '0 6px 18px rgba(0, 0, 0, 0.15)' }, // Smooth hover effect
            width: { xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(50% - 16px)' }, // Responsive width
          }}
        >
          <LeaderboardIcon 
            sx={{ 
              color: '#673ab7', 
              fontSize: { xs: 35, md: 50 } // Responsive icon size 
            }} 
          />

          <Box 
            sx={{ 
              flexGrow: 1, 
              cursor: 'pointer', 
              overflow: 'hidden' // Manage overflow on smaller screens
            }} 
            onClick={() => navigate('/programs')}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '12px', md: '16px' }, // Adjust font size for screens
                color: theme.palette.mode === 'dark' ? '#fff' : '#4a148c',
                mb: 0.3,
              }}
            >
              All Programs 🎓
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: theme.palette.mode === 'dark' ? 'lightgrey' : '#424242',
                fontSize: { xs: '10px', md: '11px' }, // Font size for mobile and desktop
                // maxWidth: '350px',
              }}
            >
              Explore programs at CrafterHack, from beginner courses to advanced challenges. Discover your next learning adventure!
            </Typography>
          </Box>

        </Paper>

      </Box>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 3, flexGrow: 1 }}>
      {/* Sticky Difficulty Section */}
      {!isMobile && (
        <Box
        sx={{
          flexBasis: { xs: '100%', sm: '25%' },
          position: 'sticky',
          top: 0,
          alignSelf: 'flex-start',
        }}
      >
        {/* New Section for Top 3 Problem Solvers */}
        <Paper
          sx={{
            padding: '16px',
            borderRadius: '16px',
            boxShadow: 3,
            backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
            '&:hover': { boxShadow: 6 },
            mb: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 1, fontSize: 14, fontWeight: 'bold', textAlign: 'center', color: '#2e7d32' }}
          >
            🏆 Top Problem Solvers
          </Typography>

          {toppers.map((solver, index) => (
            <Box
              onClick={() => handleTopperNameClick(solver.username)}
              key={solver.name}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                p: 1,
                mb: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
                borderRadius: '10px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                transition: 'box-shadow 0.2s ease-in-out',
                '&:hover': { boxShadow: '0 4px 16px rgba(0,0,0,0.2)' },
              }}
            >
              {/* Trophy Icon */}
              <EmojiEventsIcon
                sx={{
                  color: trophyColors[index],
                  fontSize: 30,
                  marginRight: '10px',
                }}
              />

              {/* Solver Name and Stats */}
              <Typography variant="body" sx={{ flexGrow: 1, fontSize: 13 }}>
                {solver.name}
              </Typography>

              {/* Solutions Count */}
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', fontSize: 10, color: 'text.secondary' }}
              >
                {solver.totalSolvedProblems} Solved
              </Typography>

              {/* Rank Icon with Tooltip */}
              <Tooltip title={`Rank: ${index + 1}`} arrow>
                <StarIcon sx={{ color: 'gold', marginLeft: 0.5, fontSize: 15 }} />
              </Tooltip>
            </Box>
          ))}

          {/* View All Ranks Button */}
          <Box sx={{ textAlign: 'center', mt: 1.5 }}>
            <Button
              variant="outlined"
              size="small"
              sx={{
                color: '#2e7d32',
                borderColor: '#2e7d32',
                '&:hover': {
                  backgroundColor: '#e8f5e9',
                  borderColor: '#1b5e20',
                },
                fontSize: 12,
              }}
              onClick={() => navigate('/leaderboard')}
            >
              View All Ranks
            </Button>
          </Box>
        </Paper>

        <Paper
          sx={{
            padding: '10px',
            mb: 2,
            borderRadius: '8px',
            boxShadow: 3,
            '&:hover': { boxShadow: 6 },
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 1, fontSize: 14, fontWeight: 'bold', textAlign: 'center', color: '#2e7d32' }}
          >
            Difficulty
          </Typography>
          {difficulties.map((difficulty) => (
            <Chip
              key={difficulty}
              label={toPascalCase(difficulty)}
              variant={selectedDifficulties.includes(difficulty) ? 'filled' : 'outlined'}
              color={selectedDifficulties.includes(difficulty) ? 'primary' : 'default'}
              sx={{
                mb: 1,
                borderRadius: '16px',
                width: 'auto',
                '&:hover': { boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)' },
                m: 0.5
              }}
              clickable
              onClick={() => handleDifficultyClick(difficulty)}
            />
          ))}
        </Paper>
      
        <Paper
          sx={{
            padding: '10px',
            borderRadius: '8px',
            boxShadow: 3,
            '&:hover': { boxShadow: 6 },
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 1, fontSize: 14, fontWeight: 'bold', textAlign: 'center', color: '#2e7d32' }}
          >
            Topics & Tags
          </Typography>
          <PerfectScrollbar>
            <div style={{ maxHeight: '600px', overflow: 'auto' }}>
              {uniqueTopics.map((topic) => (
                <Chip
                  key={topic}
                  label={toPascalCase(topic)}
                  variant={selectedTopics.includes(topic) ? 'filled' : 'outlined'}
                  color={selectedTopics.includes(topic) ? 'primary' : 'default'}
                  sx={{
                    m: 0.5,
                    borderRadius: '16px',
                    width: 'auto',
                    '&:hover': { boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)' },
                  }}
                  clickable
                  onClick={() => handleTopicClick(topic)}
                />
              ))}
            </div>
          </PerfectScrollbar>
        </Paper>
        
      </Box>
      
      )}

      {/* Scrollable Problems Section */}
      {loading ? 
      (<Box sx={{ display: 'flex', margin:'auto', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
        <CircularProgress />
      </Box>) :
      <Box
        sx={{
          flexBasis: { xs: '100%', sm: '75%' },
          overflow: 'hidden',
          maxHeight: '1100px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            width: '98%',
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mb: 1
          }}
        >
          <Paper
            component="form"
            sx={{
              p: '2px 4px',
              mb: { xs: 2, md: 0 }, // margin bottom for mobile view
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              maxWidth: { xs: '100%', md: '450px' }, // max width for search box
              width: '100%',
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Problem"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <IconButton type="submit" sx={{ p: '10px' }}>
              <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }}>
              <FilterListIcon />
            </IconButton>
            <Button variant="contained" color="primary" size="small" onClick={handlePickForMe}>
              <ShuffleIcon sx={{ marginRight: '4px' }} />
              Random
            </Button>
          </Paper>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleLeaderboardClick}
            sx={{ ml: { xs: 0, md: 2 }, mb: { xs: 1, md: 0 } }} // margin for mobile view
          >
            <LeaderboardIcon sx={{ marginRight: '4px' }} />
            Leaderboard
          </Button>
          <Btn
            type="primary"
            size="medium"
            onClick={handleLiveCodeClick}
            style={{
              backgroundColor: '#f50057', // Custom color for secondary theme
              borderColor: '#f50057',
              marginLeft: window.innerWidth < 600 ? '0' : '8px', // Responsive margin
              marginBottom: window.innerWidth < 600 ? '8px' : '0',
            }}
          >
            <CodeOutlined />
            Live Coding Room
          </Btn>
          
        </Box>



        <PerfectScrollbar>
          {filteredProblems.map((problem) => (
            <Paper
              sx={{ mb: 2, width: '98%', border: theme.palette.mode === 'dark' ? '1px solid white' : '' }}
              key={problem._id}
              className="group bg-white transition-all duration-1000 ease-in-out transform hover:scale-y-105 hover:shadow-lg hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-300"
            >
              <Link to={`/problems/${problem.slug}`}>
                <div className="flex justify-between items-center cursor-pointer p-1">
                  <Typography variant="body" sx={{ ml: 2 }} className="group-hover:text-white w-9/12">
                    {problem.title}
                  </Typography>
                  <div className="w-3/12 md:w-2/12 flex justify-between items-center">
                    <Button
                      size="small"
                      variant="outlined"
                      color={
                        problem.difficulty === 'easy'
                          ? 'success'
                          : problem.difficulty === 'medium'
                          ? 'warning'
                          : 'error'
                      }
                      className="w-1/2 mr-1 group-hover:bg-white font-bold"
                    >
                      {problem.difficulty.charAt(0).toUpperCase() + problem.difficulty.slice(1)}
                    </Button>
                    {problem.isSolved ? (
                      <CheckCircleIcon sx={{ fontSize: 20, mr: 1 }} className="text-green-700" />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Link>
            </Paper>
          ))}
        </PerfectScrollbar>
      </Box>
      }
    </Box>
  </Box>
)};

export default ProblemsPage;
