import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Stack,
  CircularProgress,
  LinearProgress,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import TimerIcon from '@mui/icons-material/Timer';
import proxy from '../api/axios';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import ProblemPage from './ProblemPage';
import { Helmet } from 'react-helmet';

const LiveContest = () => {
  const { contestId } = useParams();
  const navigate = useNavigate();
  const [contest, setContest] = useState(null);
  const [problems, setProblems] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState('All');
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [confirmSubmitDialog, setConfirmSubmitDialog] = useState(false); // Confirmation dialog state
  const [isLoading, setIsLoading] = useState(true);
  const [timerStarted, setTimerStarted] = useState(false);

  useEffect(() => {
    const fetchContestDetails = async () => {
      try {
        setIsLoading(true);
        const { data } = await proxy.get(`/contests/${contestId}`);
        setContest(data);
        setProblems(data.problems || []);
        if (data.duration <= 0) {
          toast.info('Your time is over, check results');
          navigate(`/contests/${data._id}/result`);
        }
        setTimeRemaining(data.duration * 60);
        setIsLoading(false);
        setTimerStarted(true); // Start timer only after loading is complete
      } catch (error) {
        console.error('Error fetching contest details:', error);
        toast.error(error.response.data.message);
        navigate('/contests');
      }
    };

    fetchContestDetails();
  }, [contestId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 0 && timerStarted) {
          clearInterval(interval);
          setIsDialogOpen(true);
          return 0;
        }
        return timerStarted ? prev - 1 : prev;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timerStarted]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const handleSelectProblem = (problem) => {
    setSelectedProblem(problem);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    navigate('/contests');
  };

  const handleFinalSubmit = () => {
    setConfirmSubmitDialog(true); // Open confirmation dialog
  };

  const handleConfirmSubmit = async () => {
    try {
      setIsLoading(true);
      const { data } = await proxy.put(`/contests/${contestId}/submit`);
      setIsLoading(false);
      setConfirmSubmitDialog(false);
      setTimeRemaining(0);
      setTimerStarted(false);
      setSuccessDialog(true);
    } catch (error) {
      console.error('Error submitting contest details:', error);
      toast.error('Could not submit contest');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSubmit = () => {
    setConfirmSubmitDialog(false);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialog(false);
    navigate(`/contests/${contestId}/result`);
  };

  return (
    <Box sx={{ display: 'flex', padding: '20px' }}>
      <Helmet>
        <title>{contest?.title + ' Live | CrafterHack '}</title>
        <meta name="description" content={'This is the page for participating in live contests'} />
      </Helmet>
      {isLoading && <LinearProgress sx={{ width: '100%' }} />} {/* Progress bar */}
      {contest && (
        <>
          <Box sx={{ minWidth: '100px', marginRight: '20px' }}>
            <Stack spacing={2} sx={{ alignItems: 'center' }}>
              <Button
                variant={selectedProblem === 'All' ? 'contained' : 'outlined'}
                onClick={() => handleSelectProblem('All')}
                sx={{
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  minWidth: '40px',
                }}
              >
                All
              </Button>

              {problems.map((_, index) => (
                <Button
                  key={index}
                  variant={selectedProblem === index ? 'contained' : 'outlined'}
                  onClick={() => handleSelectProblem(index)}
                  sx={{
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    minWidth: '40px',
                  }}
                >
                  {index + 1}
                </Button>
              ))}
            </Stack>
          </Box>

          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <Typography variant="h6" sx={{ marginBottom: '10px' }}>
              {contest.title}
            </Typography>

            <Box sx={{ position: 'absolute', top: '0px', right: '10px' }}>
              <Stack direction="row" alignItems="center">
                <TimerIcon color="primary" />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  {formatTime(timeRemaining) + ' Remaining'}
                </Typography>
                <Button
                  size='small'
                  variant="contained"
                  onClick={handleFinalSubmit}
                  sx={{ ml: 2 }}
                  color='success'
                >
                  Final Submit
                </Button>
              </Stack>
            </Box>

            {selectedProblem === 'All' ? (
              <Paper sx={{ padding: '15px' }}>
                <List>
                  {problems.map((problem, index) => (
                    <ListItem
                      key={problem._id}
                      sx={{
                        padding: '10px',
                        borderBottom: '1px solid #ccc',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body">{`${index + 1}. ${problem.title}`}</Typography>
                      <Button
                        size='small'
                        variant="contained"
                        onClick={() => handleSelectProblem(index)}
                      >
                        Solve
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            ) : (
                <ProblemPage contestId={contestId} problem={problems[selectedProblem]} />
            )}
          </Box>

          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}

      <Dialog open={isDialogOpen && timerStarted} onClose={handleDialogClose}>
        <DialogTitle>Time's Up!</DialogTitle>
        <DialogContent>
          <Typography>Your contest time has expired. Your solutions are automatically submitted. Thank you for participating!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Got it</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmSubmitDialog} onClose={handleCancelSubmit}>
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to submit the contest? Once submitted, you cannot make further changes.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSubmit}>Cancel</Button>
          <Button onClick={handleConfirmSubmit} variant="contained">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={successDialog} onClose={handleSuccessDialogClose}>
        <DialogTitle>Contest Submitted Successfully!</DialogTitle>
        <DialogContent>
          <Typography>Your contest submission has been recorded. Thank you!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LiveContest;
