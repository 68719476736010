import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputBase, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate } from 'react-router-dom';
import proxy from '../../api/axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';


const ManageProblems = () => {
  const loggedInUser = useSelector((state)=>state.user.userInfo);
  const [problems, setProblems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProblemId, setSelectedProblemId] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);
  const [tab, setTab] = useState('unpublished'); // Published or Unpublished
  const [levels, setLevels] = useState([]); // State to hold levels
  const [selectedLevel, setSelectedLevel] = useState('1'); // Selected level
  const [sections, setSections] = useState([]); // State to hold sections
  const [selectedSection, setSelectedSection] = useState(''); // Selected section
  const navigate = useNavigate();

  // Fetch problems from the API
  useEffect(() => {
    const fetchProblems = async () => {
      try {
        const response = await proxy.get('/problems/admin');
        setProblems(response.data); // Assuming the API returns an array of problems
        // Extract levels and sections dynamically
        const uniqueLevels = [...new Set(response.data.map(problem => problem.level))];
        setLevels(uniqueLevels.sort());

        const uniqueSections = [...new Set(response.data.map(problem => problem.section))];
        
        setSections(uniqueSections);
      } catch (error) {
        setError('Error fetching problems');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProblems();
  }, []);

  const handleDialogClose = () => {
    setConfirmDialogOpen(false);
    setPublishDialogOpen(false);
    setConfirmDeleteDialogOpen(false);
  };

  const handleConfirmUnpublish = async () => {
    try {
      await proxy.put(`/problems/unpublish/${selectedProblemId}`);
      setProblems(problems.map(problem => 
        problem._id === selectedProblemId ? { ...problem, isPublished: false } : problem
      ));
    } catch (error) {
      setError('Error unpublishing problem');
      console.error(error);
    } finally {
      handleDialogClose();
    }
  };

  const handleUnpublishProblem = (id) => {
    setSelectedProblemId(id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmPublish = async () => {
    try {
      await proxy.put(`/problems/publish/${selectedProblemId}`);
      setProblems(problems.map(problem => 
        problem._id === selectedProblemId ? { ...problem, isPublished: true } : problem
      )); // Update the local state
    } catch (error) {
      setError('Error publishing problem');
      console.error(error);
    } finally {
      handleDialogClose();
    }
  };

  const handlePublishProblem = (id) => {
    setSelectedProblemId(id);
    setPublishDialogOpen(true);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
    setSelectedSection(''); // Reset section selection on level change
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };
  const handleDeleteProblem = (id) => {
    setSelectedProblemId(id);
    setConfirmDeleteDialogOpen(true);
  };
  const handleConfirmDelete = async ()=>{
    try {
      await proxy.delete(`/problems/${selectedProblemId}`);
      setProblems(problems.filter(problem => problem._id !== selectedProblemId)); // Update the local state
      toast.success('Problem deleted successfully');
    } catch (error) {
      setError('Error deleting problem');
      console.error(error);
    } finally {
      handleDialogClose();
    }
  }

  const filteredProblems = problems
    .filter(problem => tab === 'published' ? problem.isPublished : !problem.isPublished)
    .filter(problem => selectedLevel ? problem.level === selectedLevel : true) // Filter by selected level
    .filter(problem => selectedSection ? problem.section === selectedSection : true) // Filter by selected section
    .filter(problem => problem.title.toLowerCase().includes(searchQuery.toLowerCase()));
    filteredProblems.sort((a,b)=>a.displayOrder - b.displayOrder);
  if (loading) {
    return <Typography variant="h6">Loading problems...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <Helmet>
        <title>Manage Problems | CrafterHack</title>
        <meta
          name="description"
          content={`This is the page for managing the problems offered by crafterhack to master the dsa skills`}
        />
      </Helmet>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
        Manage Problems ({filteredProblems.length})
      </Typography>

      {/* Tabs */}
      <Box sx={{ mb: 2 }}>
        <Button 
          variant={tab === 'published' ? 'contained' : 'outlined'} 
          onClick={() => setTab('published')} 
          sx={{ mr: 2 }}
        >
          Live Problems
        </Button>
        <Button 
          variant={tab === 'unpublished' ? 'contained' : 'outlined'} 
          onClick={() => setTab('unpublished')}
        >
          Drafted Problems
        </Button>
      </Box>

      <Button 
        variant="contained" 
        color="success" 
        onClick={() => navigate('/admin/create-problem')} 
        sx={{ mb: 3 }}
      >
        + New Problem
      </Button>

      {/* Search Bar and Level/Section Selectors */}
      <Paper component="form" sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <InputBase 
          sx={{ ml: 1, flex: 1, mr: 2, height: 50 }} // Move search to left
          placeholder="Search Problem"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        
      </Paper>
      <Select
        value={selectedLevel}
        onChange={handleLevelChange}
        sx={{ mb: 4, width: '100%', maxWidth: '200px' }}
      >
        {levels.map((level, index) => (
          <MenuItem key={index} value={level}>
            Level {level}
          </MenuItem>
        ))}
      </Select>
      <Select
        value={selectedSection}
        onChange={handleSectionChange}
        sx={{ mb: 4, ml:2,     width: '100%', maxWidth: '200px' }}
      >
        <MenuItem value="">
          <em>All Sections</em>
        </MenuItem>
        {sections.map((section, index) => (
          <MenuItem key={index} value={section}>
            {section}
          </MenuItem>
        ))}
      </Select>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: '#f5f5f5' }}>
              <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Level</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Section</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Difficulty</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Test Cases</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Display Order</TableCell>
              { loggedInUser?.role === 'admin' && <TableCell align="center" sx={{ fontWeight: 'bold' }}>CreatedBy</TableCell> }
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProblems.map((problem) => (
              <TableRow key={problem._id}>
                <TableCell>
                  <Button component={Link} to={`/admin/problems/${problem.slug}`}>
                  {problem.title}
                  </Button>
                </TableCell>
                <TableCell align="center">{'Level ' + problem.level}</TableCell>
                <TableCell align="center">{problem.section}</TableCell>
                <TableCell align="center">{problem.difficulty.charAt(0).toUpperCase() + problem.difficulty.slice(1)}</TableCell>
                <TableCell align="center">{problem.testCases?.length || 0}</TableCell>
                <TableCell align="center">{problem.displayOrder}</TableCell> {/* Display Order */}
                { loggedInUser?.role === 'admin' && <TableCell align="center">{problem.createdBy?.name}</TableCell> }
                <TableCell align="center">
                  <IconButton onClick={() => navigate(`/admin/edit-problem/${problem.slug}`)} color="primary">
                    <EditIcon />
                  </IconButton>
                  { loggedInUser.role ==='admin' &&  (tab === 'unpublished' ? (
                    <>
                      <Button size='small' onClick={() => handlePublishProblem(problem._id)} variant="contained" color="success">
                        Go Live
                      </Button>
                      <IconButton color='primary' onClick={()=>handleDeleteProblem(problem._id)}>
                        <DeleteIcon/>
                      </IconButton>
                    </>
                  ) : (
                    <>
                    <Button size='small' onClick={() => handleUnpublishProblem(problem._id)} variant="contained" color="success">
                      Take Down
                    </Button>
                    
                    </>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Confirm Delete Dialog */}
      <Dialog open={confirmDeleteDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this problem?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No, Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Confirm Unpulbish Dialog */}
      <Dialog open={confirmDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Unpublish</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to unpublish this problem?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmUnpublish} color="secondary">
            Take Down
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Publish Dialog */}
      <Dialog open={publishDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Publish</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to publish this problem?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmPublish} color="success">
            Go Live
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageProblems;
