import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button, Spin, Tag, Typography, Divider,  Modal, message } from 'antd';
import { PlayCircleOutlined, ClockCircleOutlined, CalendarOutlined, CheckCircleOutlined, TrophyOutlined } from '@ant-design/icons';
import { FlagOutlined  } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import proxy from '../api/axios';
import { showAuth } from '../redux/authSlice';
import { formatDateTime } from '../common/utils';

const { Title, Text } = Typography;

const formatRemainingTime = (remainingTime) => {
  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
  return { days, hours, minutes, seconds };
};

const ContestsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remainingTimes, setRemainingTimes] = useState([]);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const { data } = await proxy.get('/contests');
        setContests(data);
      } catch (error) {
        console.error('Error fetching contests:', error);
        toast.error('Failed to load contests');
      } finally {
        setLoading(false);
      }
    };
    fetchContests();
  }, []);

  useEffect(() => {
    const updateRemainingTimes = () => {
      const currentTime = new Date();
      const updatedTimes = contests.map((contest) => ({
        contestId: contest.id,
        remainingTime: new Date(contest.startTime) - currentTime,
      }));
      setRemainingTimes(updatedTimes);
    };
  
    updateRemainingTimes(); // Initial calculation
    const interval = setInterval(updateRemainingTimes, 1000); // Update every second
    return () => clearInterval(interval); // Cleanup on unmount
  }, [contests]);
  

  const handleContestClick = (contestId) => {
    if (!isLoggedIn) {
      dispatch(showAuth());
      return;
    }
    navigate(`details/${contestId}`);
  };

  const handleStartNow = async (contestId, timeRemaining) => {
    Modal.confirm({
      title: 'Start Contest Confirmation',
      content: `Are you sure you want to ${timeRemaining ? 'resume' : 'start'} this contest?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          if (!timeRemaining) {
            await proxy.post(`/contests/join`, { id: contestId });
          }
          message.success(`Contest ${timeRemaining ? 'resumed' : 'started'} successfully!`);
          navigate(`/contests/${contestId}/live`);
        } catch (error) {
          message.error('Failed to start the contest. Please try again.');
        }
      },
      onCancel() {
        console.log('User cancelled the contest start action');
      },
    });
  };

  const handleViewResults = (contestId) => {
    if (!isLoggedIn) {
      dispatch(showAuth());
      return;
    }
    navigate(`${contestId}/result`);
  };
  const isContestActive = (startTime, endTime) => {
    const currentTime = new Date();
    const contestStartTime = new Date(startTime);
    const contestEndTime = new Date(endTime);
    return contestStartTime <= currentTime && contestEndTime >= currentTime;
  };

  const isContestCompleted = (endTime) => {
    const currentTime = new Date();
    const contestEndTime = new Date(endTime);
    return contestEndTime < currentTime;
  };
  const getContestStatus = (contest) => {
    const currentTime = new Date();
    if (currentTime < new Date(contest.startTime)) {
      return 'Upcoming';
    }
    if (isContestActive(contest.startTime, contest.endTime) && contest.status !== 'Already Submitted' && contest.status !== 'Time Expired') {
      return 'In Progress';
    }
    if (isContestCompleted(contest.endTime) || contest.status === 'Already Submitted' || contest.status === 'Time Expired') {
      return 'Completed'; 
    }
    return 'Unknown';
  };

  const getTagColor = (status) => {
    switch (status) {
      case 'Upcoming':
        return '#2db7f5'; // Soft blue
      case 'In Progress':
        return '#52c41a'; // Professional green
      case 'Completed':
        return '#f5222d'; // Red
      default:
        return 'default';
    }
  };

  return (
    <div style={{ backgroundColor: '#f4f5f7', padding: '30px 30px', minHeight: '100vh' }}>
      <Helmet>
        <title>Contests | CrafterHack</title>
      </Helmet>

      <Title level={2} style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>
        <TrophyOutlined style={{ marginRight: 10, color: '#fadb14' }} /> Coding Contests
      </Title>

      <Divider style={{ borderColor: '#d9d9d9' }} />

      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <Spin size="large" />
          <Text style={{ marginLeft: 10 }}>Loading Contests...</Text>
        </div>
      ) : (
        <Row gutter={[24, 24]} justify="center">
          {contests.map((contest) => {
            const status = getContestStatus(contest);
            const remainingInfo = remainingTimes.find((t) => t.contestId === contest.id);
            const { days, hours, minutes, seconds } = formatRemainingTime(remainingInfo?.remainingTime || 0);

            return (
              <Col key={contest.id} xs={24} sm={12} md={8} lg={6}>
                <Card
                onClick={()=>handleContestClick(contest.id)}
  hoverable
  bordered={false}
  style={{
    borderRadius: '12px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    background: 'white',
    transition: 'transform 0.2s ease-in-out',
  }}
  cover={
    <div
      style={{
        height: '55px',
        background: 'linear-gradient(to right, #2980b9, #6dd5fa)',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', left: '20px', display: 'flex', alignItems: 'center' }}>
        <TrophyOutlined style={{ fontSize: '22px', marginRight: '8px' }} />
        <span style={{ fontSize: '14px' }}>Contest Arena</span>
      </div>
      <div style={{ position: 'absolute', right: '20px' }}>
        <FlagOutlined style={{ fontSize: '20px', opacity: 0.9 }} />
      </div>
    </div>
  }
  onMouseEnter={(e) => {
    e.currentTarget.style.transform = 'translateY(-4px)';
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.transform = 'translateY(0)';
  }}
  actions={[
    status === 'In Progress' ? (
      <Button
        type="primary"
        shape="round"
        onClick={(e) => {
          e.stopPropagation();  // Prevent click event from bubbling up to parent nodes
          handleStartNow(contest.id, contest.timeRemaining);
        }}
      >
        <PlayCircleOutlined /> {contest.timeRemaining ? 'Resume' : 'Start Now'}
      </Button>

    ) : (
      <Button type="primary" shape="round" disabled>
        <PlayCircleOutlined /> Start Now
      </Button>
    ),
    status === 'Completed' && (
      <Button shape="round" style={{ color: '#595959' }} onClick={() => handleViewResults(contest.id)}>
        View Results
      </Button>
    ),
  ]}
>
  {/* Status and Timer Container */}
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    }}
  >
    {/* Status Tag */}
    <Tag
      color={getTagColor(status)}
      style={{
        fontWeight: 'bold',
        borderRadius: '4px',
        fontSize: '12px',
      }}
    >
      {status}
    </Tag>

    {/* Countdown Timer */}
    {status === 'Upcoming' && (
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '14px',
          background: 'linear-gradient(to right, #00c6ff, #0072ff)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        {days}d {hours}h {minutes}m {seconds}s
      </div>
    )}
  </div>

  {/* Title */}
  <Title level={5} style={{ marginBottom: '8px', color: '#333' }}>
    {contest.title}
  </Title>

  {/* Start and End Times */}
  <Text style={{ display: 'block', marginBottom: '4px', color: '#595959' }}>
    <ClockCircleOutlined /> Starts: {formatDateTime(contest.startTime).date} at{' '}
    {formatDateTime(contest.startTime).time}
  </Text>
  <Text style={{ display: 'block', marginBottom: '4px', color: '#595959' }}>
    <CalendarOutlined /> Ends: {formatDateTime(contest.endTime).date} at{' '}
    {formatDateTime(contest.endTime).time}
  </Text>

  {/* Duration */}
  <Text style={{ display: 'block', marginBottom: '10px', color: '#595959' }}>
    <CheckCircleOutlined /> Duration: {contest.duration} mins
  </Text>
</Card>




              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default ContestsPage;
