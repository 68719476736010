import React, { useEffect, useState } from 'react';
import { 
  CircularProgress, Card, CardContent, Box, Typography, Paper, 
  Button, Chip, Divider, LinearProgress, Grid, useTheme , Avatar, 
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { toast } from 'react-toastify';
import proxy from '../../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import { showAuth } from '../../redux/authSlice';
import { Helmet } from 'react-helmet';
import { Code as CodeIcon } from '@mui/icons-material';
import ProblemPage from '../ProblemPage';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import { trophyColors, TrophyIcon } from '../LeaderboardPage';
import PreviousChallenges from './PreviousChallenges';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecordIcon';

const rules = [
  "Solve one coding problem every day without skipping.",
  "Participate in weekly coding competitions.",
  "No breaks allowed. If you miss a day, start over from Day 1.",
  "Track your daily progress for 75 consecutive days.",
  "Stay accountable—track on the leaderboard.",
];

const calculateProgress = (completed, total) => (completed / total) * 100;

const ChallengeDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInUser = useSelector((state)=>state.user.userInfo);
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [todaysProblem, setTodaysProblem] = useState(null);
  const [startSolving, setStartSolving] = useState(false);
  const [showLeaderBoard, setShowLeaderBoard] = useState(true);


  const fetchLeaderboard = async () => {
    try {
      const response = await proxy.get('/challenge/leaderboard');
      if (response.status === 200) {
        setUsers(response.data);
      } else {
        toast.error('Failed to load users');
      }
    } catch (error) {
      toast.error('Error fetching users');
    } finally {
      setLoading(false);
    }
  };

  const fetchTodaysProblem = async () => {
    try {
      const response = await proxy.get('/challenge/todays-problem');
      if (response.status === 200) {
        setTodaysProblem(response.data.problem); // Assuming the response contains the problem
      }
    } catch (error) {
      console.log('Error fetching today\'s problem');
    }
  };

  const fixStreak = async (userId) => {
    try {
      const response = await proxy.post('/challenge/fix-streak', {userId});
      toast.success(response.data);
    } catch (error) {
      console.log('Error fixing streak');
    }
  };

  const join75HardChallenge = async () => {
    if (!isLoggedIn) {
      dispatch(showAuth());
      return;
    }
    try {
      const response = await proxy.post('/challenge/join-75-hard');
      if (response.status === 200) {
        toast.success(response.data.message);
        await fetchTodaysProblem();
      } else {
        toast.error('Error joining the 75 Hard Coding challenge');
      }
    } catch (error) {
      toast.error('Error joining the 75 Hard Coding challenge');
    }
  };
  const handleUserClick = (username) => {
    navigate(`/user/${username}`);
  };
  useEffect(() => {
    fetchLeaderboard();
    fetchTodaysProblem();
  }, []);

  const submissionCallBack = async (data) => {
    if(data.status==='Accepted'){
      toast.success(`Congratulations, you have solved today's problem`);
      setTodaysProblem({...todaysProblem, isSolved: true});
      await fetchLeaderboard();
    }
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        p: { xs: 2, md: 3 },
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Helmet>
        <title>75 Hard Coding Challenge | CrafterHack</title>
        <meta
          name="description"
          content={`This is the page for viewing the 75 hard coding challenge where you'll solve the DSA problems.`}
        />
      </Helmet>

      <Paper
        sx={{
          width: { xs: '100%', md: '90%' },
          p: { xs: 2, md: 4 },
          borderRadius: '5px',
          boxShadow: 4,
          background: theme.palette.mode === 'dark'
            ? 'linear-gradient(135deg, #2e2e2e, #424242)'
            : '#fff',
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
            background: 'linear-gradient(135deg, #e0f7fa 30%, #bbdefb 100%)',
            p: 2,
            borderRadius: '12px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <EmojiEventsIcon sx={{ color: '#ff9800', fontSize: { xs: 30, md: 40 } }} />
            <Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: 600, color: '#1e88e5', fontSize: { xs: '1rem', md: '1.2rem' } }}
              >
                75 Hard Coding Challenge 🚀
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: '#616161', fontSize: { xs: '0.75rem', md: '0.8rem' } }}
              >
                Push your limits and become a coding expert in 75 days.
              </Typography>
            </Box>
          </Box>
          <Chip
            // icon={<FiberManualRecordIcon fontSize='small' />}
            label="Live Now"
            color="success"
            sx={{ fontWeight: 'bold', fontSize: { xs: '0.7rem', md: '0.8rem' } }}
          />
        </Box>

        {/* Today's Problem Section */}
        { todaysProblem ? 
        <>
        <Box sx={{ p: 2, borderRadius: 2, boxShadow: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, fontSize: { xs: '1rem', sm: '1.2rem' } }}>
          Today's Challenge
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'flex-start', sm: 'center' },
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            cursor: 'pointer',
          }}
          onClick={() => setStartSolving(!startSolving)}
        >
          <Box sx={{ width: '100%' }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: '#2e7d32', fontSize: { xs: '1rem', sm: '1.1rem' } }}
            >
              <CodeIcon sx={{ color: '#388e3c', fontSize: { xs: 15, sm: 25 }, mr:1 }} />
              {todaysProblem?.title}
            </Typography>
            <Box
              component="div"
              sx={{ color: '#424242', fontSize: { xs: '0.7rem', sm: '0.9rem' } }}
              dangerouslySetInnerHTML={{
                __html:
                  todaysProblem?.description.slice(0, 100) + (todaysProblem?.description.length > 100 ? '...' : ''),
              }}
            />
          </Box>
          <Button
            variant="contained"
            color={todaysProblem.isSolved ? 'success' : 'primary'}
            size='small'
            sx={{
              ml: { xs: 0, sm: 'auto' },
              mt: { xs: 1, sm: 0 },
              fontSize: { xs: '0.6rem', sm: '0.8rem' },
              width: { xs: '100%', sm: 'auto' },
              justifyContent: 'center',
            }}
            startIcon={todaysProblem.isSolved && <CheckCircleIcon />}
            onClick={() => setStartSolving(!startSolving)}
          >
            {!todaysProblem.isSolved ? 'Solve' : 'Solved'}
          </Button>
        </Box>

          </Box>
            {startSolving && <ProblemPage callBack={submissionCallBack} challenge="75Hard" problem={todaysProblem} />}
        </> : (
          <Box sx={{ textAlign:'center' }}>
            <Button
            variant="contained"
            size="large"
            color="success"
            startIcon={<FitnessCenterIcon />}
            sx={{ textTransform: 'none', mb:3, fontSize: { xs: '0.9rem', md: '1rem' } }}
            
            onClick={join75HardChallenge}
          >
            Start Challenge Now 💪
          </Button>
        </Box>
        )
        }

        {/* Top Streakers Section */}
        

      <Box sx={{ textAlign: 'center', my: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 3, color: '#1976D2' }}>
        🌟 Top Streakers 🌟
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {users.slice(0, 3).map((user, index) => (
            <Grid item xs={12} sm={6} md={4} key={user.id}>
              <Card
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 3,
                  borderRadius: 3,
                  boxShadow: 5,
                  bgcolor: '#333',
                  overflow: 'hidden',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.2)',
                  },
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '150%',
                    height: '150%',
                    top: '-100%',
                    left: '-100%',
                    backgroundColor: '#41a7a1',
                    borderRadius: '50%',
                    transition: 'transform 0.6s ease-in-out',
                  },
                  '&:hover::before': {
                    transform: 'scale(2)',
                  },
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: '#A8DADC',
                    color:'black',
                    width: { xs: 60, md: 70 },
                    height: { xs: 60, md: 70 },
                    fontSize: { xs: '1.5rem', md: '1.8rem' },
                    mb: 2,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  {user.name[0]}
                </Avatar>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '1.1rem', md: '1.3rem' },
                    color: '#F1FAEE',
                    textShadow: '1px 1px 4px rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {user.name}
                  <IconButton
                    aria-label="profile"
                    onClick={()=>handleUserClick(user.username)}
                    sx={{
                      color: '#F1FAEE',
                      '&:hover': { color: '#A8DADC' },
                    }}
                  >
                    <PersonIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: '0.9rem', md: '1rem' },
                    color: '#A8DADC',
                    opacity: 0.9,
                    mb: 1,
                    textShadow: '1px 1px 4px rgba(0, 0, 0, 0.3)',
                  }}
                >
                  @{user.username}
                </Typography>
                <Chip
                  label={`🔥 Streak: ${user.streak} days | Max: ${user.maxStreak} days`}
                  sx={{
                    mt: 1,
                    fontSize: { xs: '0.8rem', md: '1rem' },
                    fontWeight: 'bold',
                    paddingX: 1.5,
                    bgcolor: '#457B9D',
                    color: '#F1FAEE',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                />

              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>


        

        {/* Progress Section */}
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', mt: 4, mb: 2, color: theme.palette.text.primary }}
        >
          Track Your Progress
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="body1" sx={{ mr: 2, fontSize: '0.9rem' }}>
            <b>Days Completed:</b> {todaysProblem?.day || 0} / { 75 }
          </Typography>
          <CheckCircleIcon sx={{ color: '#4caf50', fontSize: 24 }} />
        </Box>
        <LinearProgress
          variant="determinate"
          value={calculateProgress(todaysProblem?.day || 0, 75)}
          sx={{ height: 8, borderRadius: 4 }}
        />
        <PreviousChallenges/>

        {/* Leaderboard Section */}
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            Leaderboard
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body2" sx={{ mb: 2 }}>
            Compete with other participants and see where you stand.
          </Typography>
          <Button
            variant="outlined"
            startIcon={<LeaderboardIcon />}
            sx={{ fontSize: '0.9rem' }}
            onClick={()=>setShowLeaderBoard(!showLeaderBoard)}
          >
            {showLeaderBoard ? 'Hide LeaderBoard' : 'View Leaderboard'}
          </Button>

          {showLeaderBoard && <Paper sx={{margin:'auto', mt:2, width: {xs:'100%',md: '70%'}, padding: '20px', bgcolor: 'background.default' }}>
            <List>
              {users.map((user, index) => (
                <ListItem
                  key={index}
                  sx={{
                    mb: 2,
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.005)',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={() => handleUserClick(user.username)}>
                    <ListItemAvatar>
                      {index < 3 ? (
                        <TrophyIcon color={trophyColors[index]} />
                      ) : (
                        <Avatar sx={{ bgcolor: 'secondary.main' }}>{users.indexOf(user) + 1}</Avatar>
                      )}
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <Typography variant="body" sx={{ cursor: 'pointer', fontWeight: users.indexOf(user) < 3 ? 'bold' : 'normal' }}>
                          {user.name}
                        </Typography>
                      }
                      secondary={<Typography sx={{ color: 'text.secondary' }}>🔥 Streak: {user.streak} days | Max Streak : {user.maxStreak} days</Typography>}
                    />
                  </Box>
                  {users.indexOf(user) <= 4 ? (
                    <StarIcon sx={{ color: 'warning.main' }} />
                  ) : (
                    <StarIcon sx={{ color: 'warning' }} />
                  )}
                  {loggedInUser?.role==='admin' && user.streak !== user.maxStreak && <Button onClick={()=>fixStreak(user.id)}>Fix Streak</Button>}
                </ListItem>
              ))}
            </List>
          </Paper>}
        </Box>

        {/* Rules Section */}
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', m: 2, mt:4, color: theme.palette.text.primary }}
        >
          Challenge Rules & Guidelines
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          {rules.map((rule, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AssignmentTurnedInIcon color="success" />
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.secondary, fontSize: '0.9rem' }}
                >
                  {rule}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        
      </Paper>
    </Box>
  );
};

export default ChallengeDetailsPage;
